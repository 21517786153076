import { FormRender } from "@immutablesoft/form-render"

function LoginForm(onSubmit)
{
  const formData = [{"name":"username","min":"3","max":"32","type":"text"},
                    {"name":"password","min":"5","max":"32","type":"password"}];
  const formName = {"name":"Login", "version":"1.0"};
  var formStyle = {};
  formStyle.input = { width: "92%" };
  formStyle.title = { display: "none" };
  formStyle.labels = { display: "none" };
  formStyle.span = { display: "none" };


  return (<div>
            <FormRender nameObj={formName} renderFields={formData}
                        handleSubmitFunction={onSubmit} logo={null}
                        submitString={"Login"} style={formStyle} />
          </div>);
}

export default LoginForm;
