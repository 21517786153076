import { useState, useEffect, useRef } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { FetchBackendEndpoint } from './Backend.js'

const UsagePlans = ({ data }) => {
  var userID = data.userID;
  var userPassword = data.userPassword;
  var userPublicForms = data.userPublicForms;
  var userPrivateForms = data.userPrivateForms;
  let defaultCallback = data.defaultCallback;
  var userLastLogin = data.userLastLogin;
  var userLevel = data.userLevel;

  const navigate = useNavigate();

  if (!(userID && userLevel))
    userLevel.level = -1;
//  else
//    console.log("user level " + userLevel.level);
  const userFormRef = useRef(null);
  useOutsideForm(userFormRef);
  const [newForm, setNewForm] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const [usageForm, setUsageForm] = useState(-1);

  function useOutsideForm(ref) {
    useEffect(() => {
      // Hide form buttons if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setUsageForm(-1);
        }
      }

      // Bind the event listener to the pointer over event
      document.addEventListener("pointerover", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("pointerout", handleClickOutside);
      };
    }, [ref]);
  }

/*
              <table className="columnEntrepreneur"  align="center">
                <h4>
                  Entrepreneur
                  <hr/>
                </h4>
                <tr>
                  <b>6</b> public forms
                </tr>
                <hr/>
                <tr>
                  Unlimited email submissions
                </tr>
                <hr/>
                <tr>
                <b>&nbsp;20MB</b> storage space
                </tr>
                <hr/>
                <tr>
                No usage limits*
                </tr>
                <hr/>
                <tr>
                1 user/email
                </tr>
                <hr/>
                <tr>
                Your logo
                </tr>
                <hr/>
                <tr>
                Unlimited Payments and Signatures
                </tr>
              </table>
*/

  return (
      <div style={{ position: "relative", left:"0px"}} tabIndex="0">
          <div ref={userFormRef} >
            <div className="row">
              <div className="column" align="center" style={{ backgroundColor:"#ffffff"}}>
                { Number(userLevel.level) == 0 ?
                  <div style={{fontSize:"16px", background:"#707070",
                               padding:"5px 5px", borderRadius:"5px"}}>
                    Current Plan
                  </div>
                  :
                  <div style={{fontSize:"22px"}}>
                    <br />
                  </div>
                }
                <h4>
                  Trialist
                  <br />
                  <sup>FREE</sup>
                </h4>
                <hr/>
                <b>2</b> public forms
                <hr/>
                  Unlimited submissions
                <hr/>
                <b>&nbsp;&nbsp;&nbsp;5MB</b> storage
                <hr/>
                No usage limits*
                <hr/>
                1 user/email
                <hr/>
                No branding
                <hr/>
                Payment/Sign testing**
                <hr />
                File and image testing**
              </div>
              <div id="Enterpreneur" className={Number(userLevel.level) < 1 && Number(userLevel.level) >= 0 ? "columnButton" : "column"}
                   role="button" tabIndex="0"
                   onKeyPress={(e) => e.key === 'Enter' ? document.getElementById('Enterpreneur').click() : console.log(e.key)}
                   onClick={(e) => 
                     {
                       console.log("Upgrade Enterpreneur");
                       if (Number(userLevel.level) == 0)
                         navigate('/FormAlly/ImmutableSoft/FormAlly Upgrade',
                                  { "state": {"inheritState": {"username":  userID },
                                    "redirect": process.env.REACT_APP_URL }
                                  });
                     }}
                   style={{ backgroundColor:"#C0C0C0"}}>
                { Number(userLevel.level) == 1 ?
                  <div style={{fontSize:"16px", background:"#707070",
                               padding:"5px 5px", borderRadius:"5px"}}>
                    Current Plan
                  </div>
                  :
                  Number(userLevel.level) < 1 && Number(userLevel.level) >= 0 ?
                  <div style={{fontSize:"16px", background:"#4fffff",
                               padding:"5px 5px", borderRadius:"5px"}}>
                      Upgrade Plan
                    </div>
                  :
                  <div style={{fontSize:"22px"}}>
                    <br />
                  </div>
                }
               <h4>Enterpreneur
                <br />
                <sup>$2 month</sup>
                </h4>
                <hr/>
                <b>5</b> public forms
                <hr/>
                  Unlimited submissions
                <hr/>
                <b>&nbsp;&nbsp;50MB</b> storage
                <hr/>
                No usage limits*
                <hr/>
                1 user/email
                <hr/>
                Your logo
                <hr/>
                Payments and Signatures
                <hr />
                File and image uploads
              </div>
              <div id="SmallBusiness" className={Number(userLevel.level) < 2 && Number(userLevel.level) >= 0 ? "columnButton" : "column"}
                   role="button" tabIndex="0"
                   onKeyPress={(e) => e.key === 'Enter' ? document.getElementById('SmallBusiness').click() : console.log(e.key)}
                   onClick={(e) => 
                   {
                     console.log("Upgrade Small Biz");
                     // If usage level below Small biz and logged in
                     if ((Number(userLevel.level) < 2) && (Number(userLevel.level) >= 0))
                       navigate('/FormAlly/ImmutableSoft/FormAlly Upgrade',
                                { "state": {"inheritState": {"username":  userID,
                                  "upgrade":"price_1PHeTGDNc0EHOfjF7rvIYmvW",
                                  "details":"Small Business: This yearly membership includes email support, unlimited form creations and 20 hosted public forms with payment acceptance managed by a single email address and Stripe account. Form submission data storage limit of 500MB."
                                  },
                                  "redirect": process.env.REACT_APP_URL }
                                });
                   }}
                   style={{ backgroundColor:"#C9AE5D"}}>
                { Number(userLevel.level) == 2 ?
                    <div style={{fontSize:"16px", background:"#707070",
                               padding:"5px 5px", borderRadius:"5px"}}>
                      Current Plan
                    </div>
                  :
                  Number(userLevel.level) < 2 && Number(userLevel.level) >= 0 ?
                    <div style={{fontSize:"16px", background:"#4fffff",
                               padding:"5px 5px", borderRadius:"5px"}}>
                      Upgrade Plan
                    </div>
                  :
                  <div style={{fontSize:"22px"}}>
                    <br />
                  </div>
                }
                <h4>Small Business
                <br />
                <sup>$10 month</sup>
                </h4>
                <hr/>
                <b>20</b> public forms
                <hr/>
                  Unlimited submissions
                <hr/>
                <b>500MB</b> storage
                <hr/>
                No usage limits*
                <hr/>
                Domain emails
                <hr/>
                Custom header
                <hr/>
                Payments and Signatures
                <hr />
                File and image uploads
                <hr />
                Form versioning & Data archiving
                <hr />
                NFT/ZK Proof
              </div>
              <div id="Enterprise" className={Number(userLevel.level) < 3 && Number(userLevel.level) >= 0 ? "columnButton" : "column"}
                   role="button" tabIndex="0"
                   onKeyPress={(e) => e.key === 'Enter' ? document.getElementById('Enterprise').click() : console.log(e.key)}
                   onClick={(e) => 
                   {
                     console.log("Upgrade Enterprise");
                     // If usage level below Enterprise and logged in
                     if ((Number(userLevel.level) < 3) && (Number(userLevel.level) >= 0))
                       navigate('/FormAlly/ImmutableSoft/FormAlly Upgrade',
                                { "state": {"inheritState": {"username":  userID,
                                  "upgrade":"price_1PHeUrDNc0EHOfjFKbUeiab8",
                                  "details":"Enterprise: This yearly membership includes email and phone support, unlimited hosted public forms with payment acceptance managed by any email address and per form Stripe account. Form submission data storage limit of 5GB."
                                },
                                  "redirect": process.env.REACT_APP_URL }
                                });
                   }}
                   style={{ backgroundColor:"#83B496"}}>
                { Number(userLevel.level) == 3 ?
                  <div style={{fontSize:"16px", background:"#707070",
                               padding:"5px 5px", borderRadius:"5px"}}>
                    Current Plan
                  </div>
                  :
                  Number(userLevel.level) < 3 && Number(userLevel.level) >= 0 ?
                  <div role="button" tabIndex="0" style={{fontSize:"16px", background:"#4fffff",
                               padding:"5px 5px", borderRadius:"5px"}}>
                      Upgrade Plan
                    </div>
                  :
                  <div style={{fontSize:"22px"}}>
                    <br />
                  </div>
                }
                <h4>Enterprise
                <br />
                <sup>$30 month</sup>
                </h4>
                <hr/>
                <b>Unlimited</b> forms
                <hr/>
                Unlimited submissions
                <hr/>
                <b>&nbsp;&nbsp;&nbsp;5GB</b> storage
                <hr/>
                No usage limits*
                <hr/>
                Unlimited emails
                <hr/>
                Full Control
                <hr/>
                Payments and Signatures
                <hr />
                File and image uploads
                <hr />
                Form versioning & Data archiving
                <hr />
                NFT/ZK Proof
                <hr />
                Publish submission data
                <hr />
                DRM protected files
                <hr />
                Automated Workflows             
                <hr />
                And much more...
              </div>
            </div>
          </div>
          <br />
          <sub>
            *if storage space is full, submission is only emailed
            <br/>
            **not allowed in public forms due to risk of abuse
          </sub>
          <br/>
          <br/>
          <sub>
            Have a question?
            Our <a href="mailto:sales@immutablesoft.org?subject=FormAlly plans">staff</a> is
            always ready to assist you.
          </sub>
            <br/>
          <br />
        <font color="red">
          { serverMessage }
        </font>
      </div>
      );
}

export default UsagePlans;
