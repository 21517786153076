import { useState, useEffect, useCallback, useRef } from "react"; 
import { useNavigate, useLocation } from 'react-router-dom'
//import { ReactRenderForm, RenderFormToObject, RemoveFormSeparators } from "render-form"
import { FormRender, FormRenderToObject, FormRemoveSeparators } from "@immutablesoft/form-render"
import { FetchBackendEndpoint } from '../Backend.js'
import getStripe from '../getStripe';

  /* Account hCaptcha secret is ES_440a7c381a5b47b8bce1cf7268bb3a7a
   *   FormAlly site key 6d3c4496-0d8a-48da-82c4-471620ccca8c
   *   see @hcaptcha/react-hcaptcha library and dashboard.hcaptcha.com */

function RenderPublicForm()
{
  const [serverMessage, setServerMessage] = useState("");
  const [form, setForm] = useState({ formally: {name: "", version: "1.0", username: ""},
                            form: [{ type: "text", name: "", value: "",
                                     description: "", min: "", max: "" }]});
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const session_id = query.get('session_id');
  const cancel_id = query.get('cancel_id');
  var redirect = query.get('redirect');
  if (redirect === undefined)
    redirect = null;
  var inheritState = null;
  var initState = null;

  const location = useLocation();
//  ? location.state : {};
  const initialized = useRef(false);

  let submissionData = window.sessionStorage.getItem("submissionData");
  if (submissionData)
    submissionData = JSON.parse(submissionData);

  // Read any inherit or init state variables
  //  These are used to auto populate the values in the rendered form
  //    inheritState takes presendence and also sets the field read only
  if (location)
  {
    if (location.state)
    {
      if (location.state.inheritState !== undefined)
        inheritState = location.state.inheritState;
      if (location.state.initState !== undefined)
        initState = location.state.initState;
      
      if (location.state.redirect !== undefined)
        redirect = location.state.redirect;
//      console.log(JSON.stringify(location.state));
    }
  }

  // Callback executed after form is retrieved from backend server
  const formLoadCallback = useCallback((obj) => {
//    console.log("+");
    // Perform assigning the form state variable only once
    if (!initialized.current) {
      initialized.current = true;
      setForm(obj);

      // If a stripe callback and form is valid, update payment info
      if (session_id && obj.formally.name.length > 0)
      {
        const newData = new FormData();

        newData.append('formally', JSON.stringify(obj.formally));
        newData.append('data', JSON.stringify(submissionData));
        newData.append('paid', session_id);
        FetchBackendEndpoint('formally/payment', newData, paymentCallback, setServerMessage);
      }
    }
  }, [session_id, submissionData]);

  // Load the form from the URL or redirect to notfound url
  useEffect(() => {
//    console.log(window.location.href);
    let urlHref = window.location.href.split('?')[0]; // Remove URL options
    let urlArray = urlHref.split('/');
    let formURL = process.env.REACT_APP_BACKEND_URL + urlArray[urlArray.length - 2] + '/' +
                  urlArray[urlArray.length - 1] + '.json';

//    console.log(formURL);
    fetch(formURL)
      .then(response => response.json())
        .then(obj => { formLoadCallback(obj); })
      .catch(error => {
         setServerMessage("Form " + formURL + " not found.");
         navigate("/NotFound");
      });

  }, [navigate, formLoadCallback]);

  useEffect(() => {
    // Check if this is a Stripe purchase callback
    if (session_id || cancel_id)
    {
      if (session_id)
        setServerMessage("Thank you, the information and your payment have been submitted successfully.");
      else if (cancel_id)
        setServerMessage("Your payment has been cancelled or did not succeed.");

      var addendum = "";
      if (redirect !== null)
      {
        setTimeout(function () { window.location.assign(redirect) }, 3000);
        addendum = "  Redirecting to " + redirect + " in 3 seconds.";
      }
      setServerMessage("Thank you, payment success." + addendum);
//      console.log(JSON.stringify(submissionData));
/*
      // Clear the query string from the URL
      var uri = window.location.toString();
      if (uri.indexOf("?") > 0)
      {
        var clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
      }
*/
      // If this is a re-load after payment, send receipt to backend
//      console.log(session_id + ":" + form.formally.name);
    }
  }, [cancel_id, session_id, navigate, redirect]);

  async function handleStripeCheckout(stripePk, price_ids)//form, data)
  {//price_id, count, type) { //'price_1P8ScUDNc0EHOfjFYHP2BRtK', 1, 'payment');
      const Url = process.env.REACT_APP_URL + 'formally/' + form.formally.username + '/' +
                  form.formally.name;
//      alert(encodeURI(Url));

/*        lineItems: [
          {
            price: price_id,
            quantity: count,
          },
        ],*/

      const lineItems = price_ids.map((id) => ({ price: id, quantity: 1 }));
      console.log(lineItems);
      const stripe = await getStripe(stripePk);//process.env.REACT_APP_STRIPE_PK);
      const { error } = await stripe.redirectToCheckout({
        lineItems, mode: 'payment',//'payment' or 'subscription'
        successUrl: encodeURI(Url) + "?session_id={CHECKOUT_SESSION_ID}" + (redirect !== null ? '&redirect=' + redirect : ""),
        cancelUrl: encodeURI(Url) + "?cancel_id={CHECKOUT_SESSION_ID}",
  //      customerEmail: 'customer@email.com',
      });
      console.warn(error.message);
  }


  const submitCallback = (data) => {
    if (data.success === true)
    {
      console.log("Form submit success for " + data.name);
      var addendum = "";
      if ((!submissionData) && (redirect !== null))

      {
        setTimeout(function () { window.location.assign(redirect) }, 3000);
        addendum = "  Redirecting to " + redirect + " in 3 seconds.";
      }
      setServerMessage("Thank you, the information has been submitted successfully." + addendum);

    }
    else
    {
      // Otherwise report the edit server message
      setServerMessage(data.message);
    }
  };

  const paymentCallback = (data) => {
    if (data.success === true)
    {
      console.log("Payment submit success for " + data.name);
      submissionData = null;
//      setServerMessage("Thank you, the information has been submitted successfully.");
    }
    else
    {
      // Otherwise report the edit server message
      setServerMessage(data.message);
    }
  };

  // ---------------------------------------------------------------
  // Submit button handling form submission
  // ---------------------------------------------------------------
  const handleFormSubmit = async (event) => {
    event.preventDefault(); 
    var data = new FormData(event.target);

    // Get the form name and delete
    const name = data.get("formally_name");
    data.delete("formally_name");
    const version = data.get("formally_version");
    data.delete("formally_version");
    const username = data.get("formally_username");
    data.delete("formally_username");
    const formallyName = {name, version, username};
    console.log("FormSubmit:" + JSON.stringify(formallyName));
//    var price_ids = [];
//    var quantities = [];

    // Loop through and sanitize any payment fields
    const fields = form.form;
    var prices = [];
    var stripePk = null;
    var captcha = null;
    for (var i = 0; i < fields.length; ++i)
    {
      if (fields[i].type === 'captcha')
      {
        captcha = fields[i].name;
        console.log("captcha found at field name " + captcha);
      }
      if (fields[i].type === 'purchase')
        prices.push(data.get(fields[i].name));
      else if ((fields[i].type === 'stripe') && fields[i].value.startsWith('pk_'))
        stripePk = fields[i].value;
    }
    if (prices.length > 0)
      console.log(prices + " prices with stripe pk " + stripePk);
    var formData = FormRenderToObject(data, "formally_separator_3210",
                                      inheritState, initState);
    console.log(JSON.stringify(formData, null, 2));

    // Ensure if captcha present it is completed
    if (captcha != null)
    {
      if (!formData[captcha].startsWith('P1_'))
      {
        setServerMessage("Captcha must be completed to submit form.");
        return;
      }
    }

    data = FormRemoveSeparators(data, "formally_separator_3210");

/*
    // Replace the original file name back if not a new file uploaded
    if ((inheritState != null) || (initState != null))
    {
      data.forEach(function(value, key)
      {
        if (value instanceof File)
        {
          if (formData[key] != value.name)
            console.log("Something wrong, " + formData[key] + " != " + value.name);

          if (value.name == "")
          {
            if ((inheritState != null) && inheritState[key])
              formData[key] = inheritState[key];
            else if ((initState != null) && initState[key])
              formData[key] = initState[key];
          }
        }
      });
    }
*/
    // Append submitted form definition
    data.append('formally', JSON.stringify(formallyName));
    data.append('form', JSON.stringify(formData));

    // Add inherited state if any
    //   Inherited state replaces existing entry with same value(s)
    if ((inheritState !== null) || (initState !== null))
    {
      if (inheritState === null)
        data.append('replace', JSON.stringify(initState));
      else if (initState === null)
        data.append('replace', JSON.stringify(inheritState));
      else
        data.append('replace',
            JSON.stringify(Object.assign(inheritState, initState)));
    }

    FetchBackendEndpoint('formally/submit', data, submitCallback, setServerMessage);

    // If a stripe checkout, save session data and initiate checkout
    if (stripePk && (prices.length > 0))
    {
      window.sessionStorage.setItem("submissionData", JSON.stringify(formData));
      await handleStripeCheckout(stripePk, prices);

      // Not executed. The above function redirects the browser
    }
  };

  const formStyle = [];
  formStyle.input = { width: "80%" };

  return (
    <div>
      <div style={{display: form && !serverMessage.startsWith('Thank you') ? "inherit" : "none"}}>
        <FormRender nameObj={form.formally} renderFields={form.form}
                    handleSubmitFunction={handleFormSubmit} logo={form.formally.logo}
                    submitString={"Submit"} style={formStyle}
                    state={{ inheritState, initState }} />
      </div>
      <br />
      <div align="center">
        { typeof serverMessage === "string" ?
           !serverMessage.includes("Thank you") ?
            <font color="red">
              { serverMessage ? serverMessage : <br />}
            </font> :
            serverMessage :
             <br />
        }
        <br />
        { session_id }
      </div>
      {
        session_id ?
          <p>
            Confirmation Strip Session: <b>{session_id}</b>
            <br />
            <br />
            <br  />
            Your complete receipt is below.
            <br />
            Form: {JSON.stringify(form.formally, null, 2).replaceAll('\n', '<br>')}
            <br />
            Stripe Session: {session_id}
            <br />
            Data: {JSON.stringify(submissionData, null, 2).replaceAll('\n', '<br>')}
          </p>
        :
          ""
      }
      {
        cancel_id ?
          <p>
            Payment failed or was cancelled by user
            <sub>{session_id}</sub>
            <br  />
            The data submitted but cancelled is below
            <br />
            {JSON.stringify(submissionData, null, 2).replaceAll('\n', '<br>')}
          </p>
        :
          ""
      }
    </div>
    );
}

export default RenderPublicForm;
