import { useState, useEffect, useRef } from "react"; 
import { FetchBackendEndpoint } from '../Backend.js'
import { useNavigate } from "react-router-dom";

function VerifyUser()
{
  const [serverMessage, setServerMessage] = useState("");
  const navigate = useNavigate();
  const initialized = useRef(false);

  // Load the user from the URL or redirect to notfound url
  useEffect(() => {

    const verifyCallback = (data) => {
  //    console.log("verifyCallback " + JSON.stringify(data));
      if (data.success === true)
      {

        //This will trigger the Google conversion tag
        window.gtag('config', 'AW-16597030438');
        window.gtag('event', 'conversion', {'send_to': 'AW-16597030438/2eOKCKDizroZEKasiuo9'});

        setServerMessage("User " + data.userid + " verified.");
        setTimeout(function () { navigate("/") }, 10000);
      }
      else
      {
        // Otherwise read and save in state login failure message
        setServerMessage(data.message);
        setTimeout(function () { navigate("/") }, 10000);
      }
    };

    if (!initialized.current) {
      initialized.current = true
      let urlArray = window.location.href.split("/");
      let username = urlArray[urlArray.length - 2];
      let hash = urlArray[urlArray.length - 1];

//      console.log("VerifyUser " + username + ":" + hash);

      let data = new FormData();

      // change the password in the hash in the form data
      data.append('username', username);
      data.append('hash', hash);

      FetchBackendEndpoint('verify', data, verifyCallback, setServerMessage);
    }
  }, [navigate]);

  return (
    <div>
      <div align="center">
        <button onClick={() => { navigate("/") }}>Go Home</button>
        &nbsp;&nbsp;&nbsp;&nbsp; close this tab, or wait 10 seconds and be redirected...
      </div>
      <br/>
      <br />
      { typeof serverMessage === "string" ?
          !serverMessage.includes('verified.') ?
          <font color="red">
            { serverMessage }
          </font>
          : serverMessage
            :
            <br/>
      }
    </div>
    );
}

export default VerifyUser;
