import { useState } from "react"; 
import { Link } from "react-router-dom";
import '../../style/App.css';
//import DashBoard from "../DashBoard"
import UserMenu from "../UserMenu"

function Documentation() {
  const [serverFailure, setServerFailure] = useState("");
  const [userReload, setUserReload] = useState(1);
  function incrUserReload() { setUserReload(userReload + 1) }
  const [playVideo, setPlayVideo] = useState(false);

//  const [userRelogin, setUserRelogin] = useState(1);
//  const [showLogin, setShowLogin] = useState(1);
//  function incrShowLogin() { setShowLogin(showLogin + 1) }

  // ---------------------------------------------------------------
  // Return the App page HTML
  // ---------------------------------------------------------------
  return (
    <div className="App">
      <header className="App-header">
        <img src="./header_background.png" alt="logo" width="100%" />
        <div style={{position: "relative", top: "-50px", height: "0px"}}>
            <b style={{fontSize: "32px"}}>Empower Your Passion</b>
        </div>
      </header>
      <div className="left_top_header">
        <img width="80px" alt="branding" src="./Immutable_WhiteOnTransparent_Logo.png"/>
      </div>
      <div align="center" className="center_top_header">
        <Link className="textButton" title="Dashboard" to="/">
          Dashboard
        </Link>
        &nbsp;&nbsp;
        <b style={{fontSize:"16px"}}>Documentation</b>
        &nbsp;&nbsp;
        <Link className="textButton" title="Plans and Pricing" to="/Pricing">
                    Plans
        </Link>
        <br />
        Form<i><b>Ally</b></i>
      </div>
      <div style={{ position: "relative", top:"0px", backgroundColor:"#ffffff", float:"left"}} tabIndex="0">
        <font color="red">
          { serverFailure instanceof String ? serverFailure : <br />}
        </font>
        <div style={{ width:"80%", marginLeft:"10%"}}>
          <div style={{ width:"80%", marginLeft:"10%", fontSize:"16px"}} align="left">
            <a href="#overview">Overview</a>
            &nbsp;&nbsp;&nbsp;
            <a href="#signup">Sign Up</a>
            &nbsp;&nbsp;&nbsp;
            <br />
            <a href="#create">Form Creation</a>
            &nbsp;&nbsp;&nbsp;
            <a href="#editing">Form Editing</a>
            &nbsp;&nbsp;&nbsp;
            <a href="#rendering">Form Rendering</a>
            <br />
            <a href="#submissions">Submissions</a>
            &nbsp;&nbsp;&nbsp;
             <a href="#publish">Publishing</a>
            &nbsp;&nbsp;&nbsp;
            <a href="#payments">Payments</a>
            &nbsp;&nbsp;&nbsp;
            <a href="#files">File Uploads</a>
            <br />
            <a href="#upgrading">Upgrading</a>
            &nbsp;&nbsp;&nbsp;
            <a href="#usecases">Use Cases</a>
          </div>
          <div align="left" style={{ fontSize:"18px"}}>
            <br/>
            <h4 id="overview">Overview</h4>
            This App is designed for non-technical users to create, publish and
            receive form submissions.
            To use Form<i>Ally</i> requires you
            first <a href="#signup">sign up</a> and create an account. Once
            your account is set up and you are logged in it is possible
            to <a href="#create">create/design</a> your first form. New forms
            can be <a href="#rendering">privately rendered</a> and
            the <a href="#submissions">submissions</a> verified to be working
            as you expect. Once working as intended,
            your form can be <a href="#publish">published</a> for
            others to publically view and fill out a submission. Each submission
            to a public form will result in a row in the submission table and
            an email to you containing all the submission data.
            The form <a href="#submissions">submissions</a> page can be used to view,
            order, add markups/edits to the submissions, or remove the
            submission. <a href="#editing">Editing</a> an existing form with
            submissions has limitations, so consider them
            before deciding your workflow best practices. An introductory demo
            video is below.
            <br/>
            <br/>
            { playVideo ?
                <video controls autoplay preload="auto" width="100%">
                  <source src="./FormAlly_Introduction.mp4" type="video/mp4" />
                </video>
              :
                <img alt="playVideo" src="./FormAlly-IntroVideo.png" width="100%"
                 onClick={(e) => setPlayVideo(true)} role="button" tabIndex="0"></img>
            }
            <br/>
            <div align="center">
              Introductory Video
            </div>
            <br/>
            <h4 id="signup">Sign Up</h4>
            Click the Guest profile button on top right to open the Login dialog.
            Select Sign Up button and enter your username, password and email
            address and read and accept the terms of use.
            <br />
            <br />
            <img  width="100%" alt="signup" src="./docs/SignUp.png"></img>
            <br />
            The email you sign up with will, by default, receive all your public form submissions.
            Upon sign up, a user verify link will be emailed to you. Click the link in the email
            to verify your account and then log in with the password you entered. By default, a
            login session will last one hour, after which time you will have to reenter
            your password.
            <br />
            <br />
            <img width="100%" alt="signupverify" src="./docs/SignUpVerify.png"></img>
            <br/>
            <br/>
            <h4 id="create">Form Creation</h4>
            Once signed in, at the bottom left of the page is a new form button.
            Select this button and enter a unique name for your form and complete
            the operation.
            <br/>
            <br />
            <img width="100%" alt="newform" src="./docs/NewForm.png"></img>
            <br/>
            After creation, the newly created empty form will appear in
            the Private forms section of your dashboard. Each created form has
            6 buttons that are visible if the form bar is clicked or mouse is hovered
            over it. The 6 actions and buttons in order from left to right are
            View Submissions, Render Form, Edit Form, Publish Form,
            Rename Form and Delete Form.
            <br/>
            <br />
            <img width="100%" alt="firstform" src="./docs/FirstForm.png"></img>
            <br/>
            View Submissions displays a table of all the submitted form entries from your
            users. The Render Form displays your form similarly to what your
            customers will see once public and allows submissions for testing, etc.
            Edit Form opens a form for editing, allowing you to add/change fields, reorder
            or delete fields and other edit features. Publish Form moves the folder
            from the private sandbox to a public URL that can be emailed, posted
            on social media or embedded in your website. Rename form renames the form
            and Delete Form removes the form and all submitted data permenantly.
            <br/>
            <br/>
            <h4 id="editing">Form Editing</h4>
            Forms are modified by changing, adding, removing and reordering a
            series of form input fields. The following input fields are supported:
            <br />
            <br />
            <div style={{fontSize:"16px"}}>
              <b>display</b>- Text drafted by the form creator that is
                              read only to the form viewer. For example,
                              an End User License Agreement or other
                              legal document.
              <br />
              <b>text</b>- A small text input field for user to enter short text.
              <br />
              <b>textarea</b>-  A multi-line text input field for user to
                                enter sentences and paragrahs.
              <br />
              <b>datetime-local</b>- A user selected date and time. Supports millisecond precision.
              <br />
              <b>date</b>- A user selected date value.
              <br />
              <b>timestamp</b>-  The date and time of the form submission.
                                 Supports millisecond precision and the value
                                 represents the time of submission (or last edit).
              <br />
              <b>email</b>- An email formatted string. name@domain
              <br />
              <b>tel</b>- A telephone formatted string. For example, (555)555-5555
              <br />
              <b>number</b>- An integer value (no fraction). During form creation,
                             a minimum and maximum provided will force user input to
                             be within those bounds.
              <br />
              <b>checkbox</b>- The checkbox can be On or off and is associated with a phrase.
              <br />
              <b>image</b>- A file attachment of type image. Can also be URL data tag encoded image (2MB max size for data encoded).
              <br />
              <b>URL/URI</b>- A URI encoded network resource. Can be HTTP link, URL data tag, FTP, etc.
              <br />
              <b>file</b>- A file attachment of any file type. Can also be URL data tag encoded image (2MB max size for data encoded).
              <br />
              <b>files</b>- One or more file attachments of any file type.
              <br />
              <b>signature</b>- A small virtual signature pad. Result saved as URL data tag of .png image.
              <br />
              <b>password</b>- A screen hidden text field for user entering secure information.
              <br />
              <b>stripe</b>- The Stripe Publishable Key (pk_*). Auto-filled to your
                             user profile Stripe Pk. Enterprise can edit this field
                             per form. This field indicates to the user payment is
                             required. Used upon submission to launch stripe checkout.
              <br />
              <b>purchase</b>- The Stripe price ID (price_*) for your product.
                               Not user editable but viewable.
              <br />
              <i>The following input fields are in development and coming soon.</i>
              <br />
              <b>daterange</b>- A calendar to pick a date or range of dates, with array of
                                disabled dates. A booking calendar.
              <br />
              <b>select</b>- A configurable drop-down select field.
              <br />

            </div>
            <br />
            Every text based field in a form should have a minimum and
            and maximum value. A minimum value of zero (0) makes the field
            optional. A non-zero minimum value makes the field mandatory. Dates, numbers
            and other fields should also have minimum and maximum values assigned
            to each field (date range, etc.).
            <br />
            <br />
            <img width="100%" alt="editform" src="./docs/EditForm.png"></img>
            <br/>
            Existing forms can be edited when Private, however, all existing
            submission data must be deleted/archived before changing the form.
            This is to prevent data mismatch between the old form version and data,
            and the new form version. Business plan and above will perform an
            auto-archive/merge of the old data and form, preserving the integrity
            of old submission data. Trialist and Entrepreneur plans should export
            the submission data and delete the submissions prior to editing a
            form with existing submission data. Otherwise an edit of a form with
            existing submission data will likely fail to save. If you forget and
            this happens, to avoid losing your edit changes, open a new browser
            tab of the App, login and then export/delete the old submission data
            and retry the form save in the other browser tab.
            <br/>
            <br/>
            <h4 id="rendering">Form Rendering</h4>
            Forms are rendered as the end user will experience, with each input
            field expecting user input before a submit action. Upon submit,
            each input is named and that name will be assigned to the user input
            value. For example, a text field is given the name <i>zip_code</i> and the
            user fills in the value <i>55555</i>, the resulting data is
            <i>zip_code:55555</i>. Each field results in the name of that
            field associated with the user value when stored as a form submission.
            Please name your fields thoughtfully so your data will make sense
            long term.
            <br />
            <br />
            <img width="100%" alt="renderform" src="./docs/RenderForm.png"></img>
            <br/>
            <br/>
            <h4 id="submissions">Submissions</h4>
            Submissions for a form can be viewed with the Form Submissions button.
            Displayed by default in the order of submission, the top being the most
            recent, the column names can be clicked to reorder the list.
            <br />
            <br />
            <img width="100%" alt="submissions" src="./docs/Submissions.png"></img>
            <br />
            The Edit and Trash buttons can be used to modify a submission or delete it
            respectfully. At the top of the page is an export button that can
            be used to export all the submission data to your local device as a .JSON file.
            <br />
            <br />
            A submission edit is shown below. The form is rendered with the current
            values populated in the form fields. After any edits a Change button press
            will commit the changes.
            <br />
            <img width="100%" alt="editsubmission" src="./docs/EditSubmission.png"></img>
            <br />
            <br/>
            <h4 id="publish">Publishing</h4>
            Once a private form is performing to your satisfaction it can be published.
            Only a published form will be publically accessible. A published form
            is not editable, renamable or deleteable. It must first be unpublished
            before the form can be modified. Once published a form be assigned
            a public URL and it will move into the Published section of the Dashboard
            on the App. Your usage plan may limit the number of public forms at any one time.
            <br />
            <br />
            <img width="100%" alt="publishedform" src="./docs/PublishedForm.png"></img>
            <br/>
            A click on the title of a published form will open the public
            URL for this form. Share this public link on social media, email or
            where ever you want to encourage users to use the form.
            <br/>
            <br/>
            <h4 id="payments">Payments</h4>
            To configure a form to require a payment requires you to first
            configure your user profile to include a Stripe Publishable Key (PK).
            Open the login box and click the User Profile button. Enter your
            Publishable Key in the Stripe PK field. A test PK a Stripe PK
            beginning in pk_test_, a live PK begins in pk_live_. A form with
            a live PK will not be publishable by an unverified account (Trialist).
            <br/>
            <br/>
            Once the Stripe PK is configured for your user you must add 
            a Stripe field to your form and at least one Purchase field.
            The purchase field must be populated with a Stripe Checkout
            product identifier (ID begins with price_). Be sure to use test
            product IDs with a test Stripe PK, and live product IDs with
            a live Stripe PK. Consult
            the <a href="https://docs.stripe.com/payments/checkout"
                    target="_blank" rel="noreferrer noopener">Stripe Checkout Docs</a> for
            more information on configuring products with Stripe Checkout.
            <br/>
            <br/>
            Only a published forms initial submission is transfered to Stripe for payment.
            Submissions to private forms, or edits to previous submissions,
            modify the submission data but are not passed to Stripe. Upon
            initial form submission, the submission data is first
            saved and the payment initiated with Stripe for the product(s)
            included in the form. Upon successful payment to Stripe the App
            receives a callback which updates the submission data, replacing
            the Stripe PK with the session id of the payment (ID beginning
            with cs_ or cs_test_). This ID can be used to lookup the Stripe
            purchase and is your receipt of payment. If a payment fails or
            is cancelled by the user, the submission will not be updated
            and the Stripe Pk field will contain the original Pk.
            <br/>
            <br/>
            Because it requires your private key, the FormAlly does not verify
            your product IDs or PK with Stripe. It is highly recommended that
            you test your payment forms with Stripe test IDs first. Once it
            behaves correctly, unpublish the form, delete all test submissions
            and edit the form to include the live Stripe Pk (pk_*) and product
            IDs (price_*) and then republish and share.
            <br/>
            <br/>
            <h4 id="files">Files</h4>
            It is possible to allow your users to submit image and files along
            with their data. To do so, add one or more <i>file</i>, <i>files</i>,
            or <i>image</i> fields in your form. Upon form submission any files 
            selected to be uploaded by the user will
            be stored as private data that is only downloadable by you
            from within the View Submissions page. Note that allowing
            your users to upload files can quickly exhaust your allowed
            storage space and is recommended for Business or Enterprise
            plans only. Allowed in Entrepreneur plan, it is recommended for
            usage flows that are low volumen and/or that remove the submission
            quickly after submission. In the Trialist plan, files and images are
            allowed in private forms for testing and prototyping.
            <br/>
            <br/>
            User uploaded files are stored seperately per form and if a duplicate
            file is uploaded, it will be renamed automatically.
            When a submission is modified, if a new file is selected, the
            old uploaded file(s) will be removed and replaced by the new file(s). 
            If a submission is modified but no new file is selected, the old file
            will be maintained.
            <br/>
            <br/>
            <h4 id="upgrading">Upgrading</h4>
            Your Trialist usage plan is upgradeable at anytime. First log into
            the account you wish to upgrade and open the Pricing Pricing link
            at the top of the page. Compare plans and click on the plan you want.
            If you are not logged in the plans will not be clickable.
            <br/>
            <br/>
            Complete the form with payment and your new plan will take effect
            immediately for that user. After upgrade be sure to revisit the
            User Profile (in user login/sign in drop menu) as more options
            may be available. If a non-profit or you have financial hardships,
            please email us for a link to purchase an Enterpreneur upgrade for
            $0. This financial step is required for us to keep abuse, and KYC
            costs, low.
            <br/>
            <br/>
            <h4 id="usecases">Use Cases</h4>
            Forms for contacting, polling, booking, purchasing are straight
            forward to build. Please share your form use cases with us (email
            at bottom of page) and we would be happy to provide feedback
            to help you improve your form, and us to improve this product!
            
            More advanced uses are described below.
            <br/>
            <br/>
            <b>Elevate an App/website</b>. Not only
            Publish forms, but submission data as well. With submission
            data your back-end data, the forms themselves become the front-end
            to a dynamic App/website, allowing admins and/or users to submit
            and manage your App content.
            <br/>
            <br/>
            <b>Automate purchasing processes</b>. Clean turn-key integration
            from purchase data to DRM and access rights. With support for
            refunds and connected to file/stream access and/or your App, let us
            automate your payment and access control needs.
            <br/>
            <br/>
            <b>Custom workflows - direct form submissions to another
            users queue</b>. A users queue of submissions can be edited, extended,
            deleted and/or forwarded to another users queue. From outreach
            through billing to legal and delivery; automate and track
            internal and external processes. Set up simple or complex workflows
            with queued form submissions.
            <br/>
            <br/>
            <b>Zero-Knowledge (ZK) proofs</b>. A fresh user submission or
            queued submission can have a ZK proof of the data stored forever
            and immutable on the blockchain as a Non-Fungible Token (NFT). When the
            data is published as well as the ZK proof, the resulting NFT is a
            self-verfiable certificate of that data.
            When issued/verified by an authoritative entity, the NFT will represent
            a 3rd party verifiable proof of attendence, graduation, ownership,
            license to use, formal invitation to a special occasion, etc.
            When data remains private and only the ZK proof in stored in the public
            NFT, trade secrets, private wills, patents and other intellectual
            property can be protected without disclosure.
            <br/>
            <br/>
            Still have questions?
            Our <a href="mailto:support@immutablesoft.org?subject=FormAlly support">staff</a> is
            always ready to assist you.
            <br/>
            <br/>
          </div>
        </div>
      </div>
      <UserMenu homeData={{ setServerFailure, incrUserReload, userRelogin : 1, showLogin : 1 }} />
      <br />
      <br />
      <br />
      <br />
      <div style={{fontSize:"10px"}} align="left">
        © 2024 <a href="https://www.immutablesoft.org/">ImmutableSoft Inc.</a>
        <br/>
        <sup>UIcons by <a href="https://www.flaticon.com/uicons" target="_blank" rel="noreferrer noopener">Flaticon</a></sup>
        <br />
      </div>
    </div>
  );
}

export default Documentation;
