import { useState, useEffect, useRef } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { FetchBackendEndpoint } from './Backend.js'

const DashBoard = ({ data }) => {
  var userID = data.userID;
  var userPassword = data.userPassword;
  var userPublicForms = data.userPublicForms;
  var userPrivateForms = data.userPrivateForms;
  let defaultCallback = data.defaultCallback;
  var userLastLogin = data.userLastLogin;
  var userLevel = data.userLevel;
//  var userStripePk = data.userStripePk;
  const navigate = useNavigate();

  const userFormRef = useRef(null);
  useOutsideForm(userFormRef);
  const [newForm, setNewForm] = useState(false);
  const [menuForm, setMenuForm] = useState(-1);
  const [renameForm, setRenameForm] = useState(-1);
  const [newFormObj, setNewFormObj] = useState({ formally: {name: "XYZ", version: "1.0", username: ""},
                            form: [{ type: "text", name: "", value: "",
                                     description: "", min: "", max: "" }]});
  const [formAllowed, setFormAllowed] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const [playVideo, setPlayVideo] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);

  function useOutsideForm(ref) {
    useEffect(() => {
      // Hide form buttons if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuForm(-1);
        }
      }

      // Bind the event listener to the pointer over event
      document.addEventListener("pointerover", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("pointerout", handleClickOutside);
      };
    }, [ref]);
  }

  const userAdminCallback = (result) => {
    if (result.success === true)
    {
      //[{name: tmpUserName, modified: modifyTime,
      //  last_login: tempUser.user.last_login}]
      var usersObj = JSON.parse(result.users);
      setAdminUsers(usersObj);
      setServerMessage(result.message + (result.action >= 2 ?
                       ": deleted " : ": found ") +
                       usersObj.length + " users.");
    }
    else
    {
      setServerMessage(result.message);
    }
  };

  const handleQueryUsers= (e, user) => {
    e.preventDefault();

    // change the password in the hash in the form data
    const newData = new FormData();
    newData.append('username', userID);
    newData.append('password', userPassword);
    newData.append('action', 0);
    newData.append('name', user);

    FetchBackendEndpoint('useradmin', newData, userAdminCallback, setServerMessage);
  }

  const handleQueryUnverifiedUsers= (e) => {
    e.preventDefault();

    // change the password in the hash in the form data
    const newData = new FormData();
    newData.append('username', userID);
    newData.append('password', userPassword);
    newData.append('action', 1);
//    newData.append('name', user));

    FetchBackendEndpoint('useradmin', newData, userAdminCallback, setServerMessage);
  }

  const handleDeleteUser = (e, user) => {
    e.preventDefault();

    // change the password in the hash in the form data
    const newData = new FormData();
    newData.append('username', userID);
    newData.append('password', userPassword);
    newData.append('action', 2);
    newData.append('name', user);

    FetchBackendEndpoint('useradmin', newData, userAdminCallback, setServerMessage);
  }

  const handleDeleteUnverifiedUsers = (e) => {
    e.preventDefault();

    // change the password in the hash in the form data
    const newData = new FormData();
    newData.append('username', userID);
    newData.append('password', userPassword);
    newData.append('action', 3);
    newData.append('name', null);

    FetchBackendEndpoint('useradmin', newData, userAdminCallback, setServerMessage);
  }

  const handleFormNameChange= (e) => { 
    e.preventDefault();
    const newName = e.target.value; 

    let found = userPrivateForms.find((element) => element.formally.name === newName);

    if (!found)
      found = userPublicForms.find((element) => element.formally.name === newName);

    if ((found) || (newName.length < 3))
      setFormAllowed(false);
    else
      setFormAllowed(true);
    const newForm = newFormObj;
    newForm.formally.name = newName;
    newForm.formally.username = userID;
    setNewFormObj(newForm);
//    console.log(JSON.stringify(newForm));
  }

  const handleNewFormSubmit= (e) => {
    e.preventDefault();

    // change the password in the hash in the form data
    const newData = new FormData();
    newData.append('username', userID);
    newData.append('password', userPassword);
    newData.append('formally', JSON.stringify(newFormObj.formally));
    newData.append('form', JSON.stringify(newFormObj.form));

    FetchBackendEndpoint('formally/save', newData, defaultCallback, setServerMessage);
    setNewForm(false);

    navigate("/EditForm", { "state": { "form": newFormObj }});
  }

  const handleRenameFormSubmit= (e, i) => {
    e.preventDefault();

    // change the password in the hash in the form data
    const newData = new FormData();
    newData.append('username', userID);
    newData.append('password', userPassword);
    newData.append('formally', JSON.stringify(userPrivateForms[i].formally));
    newData.append('newname', newFormObj.formally.name);

    FetchBackendEndpoint('formally/rename', newData, defaultCallback, setServerMessage);
    setRenameForm(-1);
  }

  const handlePublishSubmit = async (event, index, mode) => {
    event.preventDefault();

    if (Number(userLevel.level) === 0)
    {
      alert("Your trial account needs an upgrade to publish a form. Click your " +
            "account in top left and then Upgrade.");
      return;
    }

    const newData = new FormData();

    // change the password in the hash in the form data
    newData.append('username', userID);
    newData.append('password', userPassword);

    if (mode)
    {
      newData.append('formally', JSON.stringify(userPrivateForms[index].formally));
      FetchBackendEndpoint('formally/publish', newData, defaultCallback, setServerMessage);
    }
    else
    {
      newData.append('formally', JSON.stringify(userPublicForms[index].formally));
      FetchBackendEndpoint('formally/unpublish', newData, defaultCallback, setServerMessage);
    }
  };

  const handleDeleteSubmit = async (event, index) => {
    event.preventDefault();

    if (window.confirm("Are you sure you wish to delete this form and ALL submitted data?"))
    {
      const newData = new FormData();

      // change the password in the hash in the form data
      newData.append('username', userID);
      newData.append('password', userPassword);

      newData.append('formally', JSON.stringify(userPrivateForms[index].formally));
      FetchBackendEndpoint('formally/delete', newData, defaultCallback, setServerMessage);
    }
  };

  return (
      <div>
        <div style={{display: userID ? "none" : "inherit"}} >
          <div style={{float:"left", width:"70%", fontSize: "calc(8px + 2vmin)"}}>
            Greetings and thank you for visiting Form<i>Ally</i>, a simple and powerful
            form creation and data submission App.
            A non-profit inclusive to all and respectful of privacy, we promise to never
            monetize your data, limit your App use or withhold your form submissions.
            Please Sign Up and <b>enjoy the App for free</b>.
            We strive to be your trusted <i>ally</i> when
            it comes to forms and data. Entrust <b>Form<i>Ally</i></b> to <b>Empower your Passion</b>.
            <br/>
            <br/>
            Non-profits/charities and the economically disadvantaged typically qualify for a free upgrade.
            Sign up
            and <a href="mailto:sales@immutablesoft.org?subject=FormAlly nonprofit upgrade">email us</a> your
            situation and goals.
            <br />
            <br />
            <sub>Returning user? Thank you for considering
            a <a href="https://www.immutablesoft.org/#how_it_work" target="_blank"  rel="noreferrer noopener">donation</a> or
            an <Link title="Plans and Pricing" to="/Pricing">upgrade</Link> to your plan.</sub>
            <br/>
            <br/>
            <br/>
          </div>
          <div width="30%" style={{float:"left", width:"30%"}}>
            <br/>
            <br/>
            { playVideo ?
                <video controls autoplay width="100%">
                  <source src="./FormAlly_Introduction.mp4" type="video/mp4" />
                </video>
              :
                <img alt="playVideo" src="./FormAlly-IntroVideo.png" width="100%"
                 onClick={(e) => setPlayVideo(true)} role="button" tabIndex="0"></img>
            }
            <br/>
            <div align="center">
              Introductory Video
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>
        </div>
        <div style={{display: userID ? "inherit" : "none", position: "relative", top:"0px", backgroundColor:"#ffffff"}}>
          <div ref={userFormRef} >
          {
            (userPublicForms && userPublicForms.length > 0) ?
            <div>
              <b style={{fontSize:"28px"}}>Published</b>
              <hr/>
              {
                (userPublicForms !== null || userPublicForms !== undefined) ? userPublicForms.map((form, index) => (
                  <div key={"public" + index}>
                    <div role="button" tabIndex="0" style={{fontSize: "24px"}} className="published-box"
                     width="100%" onPointerOver={() => { setTimeout( () => { setMenuForm(userPrivateForms.length + index)}, 250)}}
                     onFocus={() => { setMenuForm(userPrivateForms.length + index)}}>
                      <a href={process.env.REACT_APP_URL + "formally/" + form.formally.username + "/" + form.formally.name}>{form.formally.name}</a>
                      <div >
                          <Link title="View Submissions" style={{visibility: menuForm === (userPrivateForms.length + index) ||
                                                                 (userLastLogin < form.formally.last_submit) ?
                                                                   "inherit" : "hidden"}}
                                className="formButton" to="/ViewFormData" state={{ form }}>
                            &nbsp;&nbsp;
                            <img alt="submissions" src="./table-pivot.svg" width="20px"></img>
                            {
                              (userLastLogin < form.formally.last_submit) ?
                                <sup><font color="red">* </font></sup>
                                :
                                <>&nbsp;&nbsp;</>
                            }
                          </Link>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Link title="Render Form" style={{visibility: menuForm === userPrivateForms.length + index ? "inherit" : "hidden"}}
                                className="formButton" to="/RenderForm" state={{ form }}>
                            &nbsp;&nbsp;
                            <img alt="render" src="./enter.svg" width="20px"></img>
                            &nbsp;&nbsp;
                          </Link>
                          &nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <button title="Unpublish" style={{visibility: menuForm === userPrivateForms.length + index ? "inherit" : "hidden"}}
                                  onClick={(e) => handlePublishSubmit(e, index, false)}>
                            &nbsp;&nbsp;
                            <img alt="unpublish" src="./cloud-download.svg" width="20px"></img>
                            &nbsp;&nbsp;
                          </button>
                      </div>
                    </div>
                  </div>
                ))
                :
                ""
              }
            </div>
            :
            ""
          }
          <br />
                <div align="left" style={{position: "relative", bottom: "-32px"}}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {
                    newForm ?
                      <>
                        <input 
                          type="text"
                          placeholder="Form Name"
                          name="formname"
                          onChange={(e) => handleFormNameChange(e)} 
                          required 
                        />
                        &nbsp;&nbsp;&nbsp;
                        {
                          formAllowed ?
                          <button title="Create" onClick={(e) => handleNewFormSubmit(e)}>
                               <>
                                &nbsp;&nbsp;
                                <img alt="create" src="./add-document.svg" width="20px"></img>
                                &nbsp;&nbsp;
                               </>
                          </button>
                          :
                          ""
                        }
                      </>
                    :
                    ""
                  }
                  &nbsp;
                  <button title={ newForm ? "Cancel" : "New Form"} onClick={(e) => { newForm ? setNewForm(false) : setNewForm(true); setFormAllowed(false);}}>
                    {
                      newForm ?
                               <>
                                &nbsp;&nbsp;
                                <img alt="cancel" src="./cross-circle.svg" width="20px"></img>
                                &nbsp;&nbsp;
                               </>
                      :
                       <>
                            &nbsp;&nbsp;
                            <img alt="create" src="./add-document.svg" width="20px"></img>
                            &nbsp;&nbsp;
                       </>
                    }
                  </button>
                </div>
          {
            (userPrivateForms && userPrivateForms.length > 0) ?
              <div>
                <b style={{fontSize:"28px"}}>
                  Unpublished
                </b>
                <hr/>
                {
                  userPrivateForms.map((form, index) => (
                  <div key={"private" + index}>
                    <div role="button" tabIndex="0" style={{fontSize: "24px"}} className="unpublished-box"  width="100%"
                     onPointerOver={() => { setTimeout( () => { setMenuForm(index)}, 250)}}
                     onFocus={() => { setMenuForm(index)}}>
                      {form.formally.name}
                      <div style={{visibility: menuForm === index ? "inherit" : "hidden"}}>
                        <Link title="View Submissions" style={{visibility: renameForm === index ? "hidden" : "inherit"}} className="formButton" to="/ViewFormData" state={{ form }}>
                            &nbsp;&nbsp;
                            <img alt="submissions" src="./table-pivot.svg" width="20px"></img>
                            &nbsp;&nbsp;
                        </Link>
                        &nbsp;&nbsp;
                        <Link title="Render Form" style={{visibility: renameForm === index ? "hidden" : "inherit"}} className="formButton" to="/RenderForm" state={{ form }}>
                            &nbsp;&nbsp;
                            <img alt="render" src="./enter.svg" width="20px"></img>
                            &nbsp;&nbsp;
                        </Link>
                        &nbsp;&nbsp;
                        <Link title="Edit Form" style={{visibility: renameForm === index ? "hidden" : "inherit"}} className="formButton" to="/EditForm" state={{ form }}>
                            &nbsp;&nbsp;
                            <img alt="edit" src="./file-edit.svg" width="20px"></img>
                            &nbsp;&nbsp;
                        </Link>
                        &nbsp;&nbsp;
                        <button title="Publish Form" style={{visibility: renameForm === index ? "hidden" : "inherit"}} onClick={(e) => handlePublishSubmit(e, index, true)}>
                            &nbsp;&nbsp;
                            <img alt="publish" src="./cloud-upload.svg" width="20px"></img>
                            &nbsp;&nbsp;
                        </button>
                        &nbsp;&nbsp;
                          {
                            renameForm === index ?
                              <>
                                <input 
                                  type="text"
                                  placeholder="Form Name"
                                  name="formname"
                                  defaultValue={form.formally.name}
                                  onChange={(e) => handleFormNameChange(e)} 
                                  required 
                                />
                                &nbsp;&nbsp;&nbsp;
                                {
                                  formAllowed ?
                                  <button title="Save" onClick={(e) => handleRenameFormSubmit(e, index)}>
                                    &nbsp;&nbsp;
                                    <img alt="save" src="./cloud-back-up-alt.svg" width="20px"></img>
                                    &nbsp;&nbsp;
                                  </button>
                                  :
                                  ""
                                }
                              </>
                            :
                            ""
                          }
                          &nbsp;
                          <button title={ renameForm  === index ? "Cancel" : "Rename Form" } onClick={(e) => { renameForm >= 0 ? setRenameForm(-1) : setRenameForm(index); setFormAllowed(false);}}>
                            {
                              renameForm === index ?
                               <>
                                &nbsp;&nbsp;
                                <img alt="cancel" src="./cross-circle.svg" width="20px"></img>
                                &nbsp;&nbsp;
                               </>
                              :
                               <>
                                &nbsp;&nbsp;
                                <img alt="rename" src="./replace.svg" width="20px"></img>
                                &nbsp;&nbsp;
                               </>
                            }
                          </button>
                        &nbsp;&nbsp;
                        <button title="Delete Form" style={{visibility: renameForm === index ? "hidden" : "inherit"}} onClick={(e) => handleDeleteSubmit(e, index)}>
                            &nbsp;&nbsp;
                            <img alt="delete" src="./trash-slash.svg" width="20px"></img>
                            &nbsp;&nbsp;
                        </button>
                      </div>
                    </div>
                  </div>
                  ))
                }
              </div>
              :
              ""
            }
          </div>
          <br />
          <br />
        </div>
        <div style={{display: userID == "ImmutableSoft" ? "inherit" : "none", position: "relative", top:"0px", backgroundColor:"#ffffff"}}>
          <b style={{fontSize:"28px"}}>User Administration</b>
          <br />
          <button title="All Users" onClick={(e) => handleQueryUsers(e, null)}>
             &nbsp;&nbsp;
             Query All Users
             &nbsp;&nbsp;
          </button>
             &nbsp;&nbsp;
             &nbsp;&nbsp;
          <button title="Unverified Users" onClick={(e) => handleQueryUnverifiedUsers(e)}>
             &nbsp;&nbsp;
             Query Unverified Users
             &nbsp;&nbsp;
          </button>
          <br />
          <button title="Delete Unverified Users" onClick={(e) => handleDeleteUnverifiedUsers(e)}>
             &nbsp;&nbsp;
             Delete Unverified Users
             &nbsp;&nbsp;
          </button>
          <br />
          {
            adminUsers.map((user, index) => (
              <div key={"admin" + index} style={{fontSize:"calc(4px + 2vmin)"}}>
                { user.name + "(" + user.email +") mtime " + user.modified + " lltime " + user.last_login }
              </div>
            ))
          }
          {
            (adminUsers.length > 0) ?
              <a href={"mailto:sales@immutablesoft.org?subject=FormAlly&bcc=" + 
                adminUsers.map((user, index) => (
                  user.email
                )).toString().replaceAll(',', ';')}>BCC email this list</a>
              :
              ""
          }
        </div>
        <font color="red">
          { serverMessage }
        </font>
      </div>
      );
}

export default DashBoard;
