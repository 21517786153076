import { useState, useEffect, useRef } from "react"; 
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";
import '../style/App.css';
import LoginForm from './Login';
import SignUpForm from './SignUp';
import { FetchBackendEndpoint } from './Backend.js';
import { FormRemoveSeparatorsAndHeader } from "@immutablesoft/form-render";

const UserMenu = ({homeData}) => {
  const userMenuRef = useRef(null);
  useOutsideMenu(userMenuRef);
/*
  const inputAutofilledRef = useRef(null);
  useInputAutofilled(inputAutofilledRef);
*/
  const [loginFails, setLoginFails] = useState(0);
  const [userID, setUserID] = useState("");
  const [userLogo, setUserLogo] = useState();
  const [userLevel, setUserLevel] = useState(0);
  const [userUsage, setUserUsage] = useState(0);  
  const [userStripePk, setUserStripePk] = useState("");  
  const [userTimeout, setUserTimeout] = useState(60);  
  const [openAccount, setOpenAccount] = useState(false);
  const [signUp, setSignUp] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);

  const LevelTrialist = 0;
  const LevelEntrepreneur = 1;
  const LevelBusiness = 2;
  const LevelEnterprise = 3;

  // ---------------------------------------------------------------
  // Callback for login
  // ---------------------------------------------------------------
//  const loginCallback = useCallback((result) => {
  const loginCallback = async (result) => {
//    console.log("loginCallback: " + result.message);
    if (result.success === true)
    {
//      console.log("Login success: ");// + JSON.stringify(result));

      // Read and save the userID from server response
      setUserID(result.userid);
//      if (result.logo && result.logo.length > 0)
//        setUserLogo(process.env.REACT_APP_BACKEND_URL + result.userid + "/data/FormAlly Profile/" + result.logo);
      setUserLogo(result.logo);
//      setUserTimeout(result.timeout);
      setUserStripePk(result.stripe_pk);
      window.sessionStorage.setItem("userID", result.userid);
      window.sessionStorage.setItem("userPublicForms", JSON.stringify(result.public));
      window.sessionStorage.setItem("userPrivateForms", JSON.stringify(result.private));
      if (result.logo)
        window.sessionStorage.setItem("userLogo", result.logo);
      window.sessionStorage.setItem("userLastLogin", result.last_login);
      setOpenAccount(false);
      setSignUp(false);
      homeData.setServerFailure("");
//      console.log(JSON.stringify(data.last_login));
      setLoginFails(0);
      setUserLevel(result.level);
      setUserUsage(result.usage);
      setUserTimeout(result.timeout);
//      console.log("Login success");
      window.sessionStorage.setItem("userLevel", JSON.stringify(result.level));
      window.sessionStorage.setItem("userUsage", result.usage);
      window.sessionStorage.setItem("userStripePk", result.stripe_pk);
      homeData.incrUserReload();
    }
    else
    {
      // Otherwise clear state and set login failure message
      setUserID(null);
      setUserLogo(null);
      window.sessionStorage.removeItem("userID");
      window.sessionStorage.removeItem("userPublicForms");
      window.sessionStorage.removeItem("userPrivateForms");
      window.sessionStorage.removeItem("userPassword");
      window.sessionStorage.removeItem("userLogo");
      window.sessionStorage.removeItem("userLastLogin");
      window.sessionStorage.removeItem("userLevel");
      window.sessionStorage.removeItem("userStripePk");
      homeData.setServerFailure(result.message);
      setLoginFails(loginFails + 1);
      setUserLevel({level:LevelTrialist,expires:0});
      homeData.incrUserReload();
      if (result.message.endsWith('expired'))
        setOpenAccount(true);
    }
  }//, [homeData, loginFails, setLoginFails]);
/*
  // If on page (re)load the user/password are in session, redo login
  //   This is the easy way to keep form data arrays in sync ;-)
  useEffect(() => {
    console.log("UserMenu - Reload user information from session: " + homeData.userReload);
    const uid = window.sessionStorage.getItem("userID");
    const logo = window.sessionStorage.getItem("userLogo");
    setUserID(uid);
    setUserLogo(logo);
    console.log("Reload " + uid + " logo " + logo + "from session: " + homeData.userReload);
//    setUserLogo(window.sessionStorage.getItem("userLogo"));
//    setUserID(window.sessionStorage.getItem("userID"));
//    setUserLogo(window.sessionStorage.getItem("userLogo"));
//    setUserPassword(window.sessionStorage.getItem("userPassword"));
//    setUserPublicForms(JSON.parse(window.sessionStorage.getItem("userPublicForms")));
//    setUserPrivateForms(JSON.parse(window.sessionStorage.getItem("userPrivateForms")));
//    setUserLastLogin(window.sessionStorage.getItem("userLastLogin"));
    setUserLevel(window.sessionStorage.getItem("userLevel"));
    setUserUsage(window.sessionStorage.getItem("userUsage"));
  }, [homeData.userReload]);
*/
  // If on page (re)load the user/password are in session, redo login
  //   This is the easy way to keep form data arrays in sync ;-)
  useEffect(() => {
      setOpenAccount(homeData.showLogin > 0 ? true : false);
      setUserID("");
  }, [homeData.showLogin]);

  // If on page (re)load the user/password are in session, redo login
  //   This is the easy way to keep form data arrays in sync ;-)
  useEffect(() => {
      const user = window.sessionStorage.getItem("userID");
      const password = window.sessionStorage.getItem("userPassword");

//      console.log("Pre Auto-relogin ");
      if (user && password && (homeData.userRelogin))
      {
//        console.log("Auto-relogin " + user + ":" + password +
//                    "(" + homeData.userRelogin + ")");
        const data = new FormData();

        // change the password in the hash in the form data
        data.append('username', user);
        data.append('password', password);

        FetchBackendEndpoint('login', data, loginCallback, homeData.setServerFailure);

        // Temporarily clear userID as login is in-progress
        //   Prevent's rerender from duplicate auto-login 
        window.sessionStorage.removeItem("userID");
      }
  }, [homeData.userRelogin, homeData.setServerFailure]);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideMenu(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
//          alert("You clicked outside of me!");
          setOpenAccount(false);
          setSignUp(false);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

/*
  function useInputAutofilled(inputRef)
  {
    useEffect(() => {
      try {
        console.log(JSON.stringify(inputRef));
        if (inputRef.current.matches(':autofill') ||
            inputRef.current.matches(':-webkit-autofill'))
        {
          setSignUp(false);
        }
      }
      catch (err)
      {
        if (inputRef.current.matches(':-webkit-autofill'))
        {
          setSignUp(false);
        }
      }
    }, [inputRef]);    
  }
*/

  // ---------------------------------------------------------------
  // Callback for backend endpoint calls
  // ---------------------------------------------------------------
  const signInCallback = (result) => {
    if (result.success === true)
    {
      // Read and save the userID from server response
      setSignUp(false);
      setOpenAccount(false);
      homeData.setServerFailure("Registration success. Check your email for link to verify your account. If in Spam/Junk, mark as Not Spam.");
    }
    else
    {
      // Otherwise read and save in state login failure message
      homeData.setServerFailure(result.message);
    }
  };

  const resetCallback = (result) => {
    if (result.success === true)
    {
      // Read and save the userID from server response
      setResetPassword(false);
      setLoginFails(0);
      homeData.setServerFailure("Reset email sent. Check your email to reset your account.");
    }
    else
    {
      // Otherwise read and save in state login failure message
      homeData.setServerFailure(result.message);
    }
  };

  const handleLoginSubmit = async event => {
    event.preventDefault();

    const rawFormData = new FormData(event.target);
    const data = FormRemoveSeparatorsAndHeader(rawFormData);
    const password = data.get('password');
    const currentTime = new Date();
    var passHash = sha256(password);

    // Strip off leading zeros
    while (passHash[0] === '0')
      passHash = passHash.substring(1);

    // Preceed with hex and compare to the release hash
    passHash = '0x' + passHash;

//    console.log(passHash);

    var hash = sha256(passHash + Number(currentTime.getTime()));
    data.append('timestamp', Number(currentTime.getTime()));

//    console.log(data.get('timestamp'));

    // Strip off leading zeros
    while (hash[0] === '0')
      hash = hash.substring(1);

    // Preceed with hex and compare to the release hash
    hash = '0x' + hash;

//    console.log(hash);

    // change the password in the hash in the form data
    data.set('password', hash);

    FetchBackendEndpoint('login', data, loginCallback, homeData.setServerFailure);
    window.sessionStorage.setItem("userPassword", hash);
  };

  const handleSignUpSubmit = async event => {
    event.preventDefault();

    // Read the form data and append the signature
    const rawFormData = new FormData(event.target);
    const data = FormRemoveSeparatorsAndHeader(rawFormData);

    const password = data.get('password');
    const agree = data.get('agree');
    const captcha = data.get('captcha');
    console.log("agree:" + agree + " captcha: " + captcha);
    if ((agree === "on") && captcha.startsWith('P1_'))
    {
      var hash = sha256(password);

      // Strip off leading zeros
      while (hash[0] === '0')
        hash = hash.substring(1);

      // Preceed with hex and compare to the release hash
      hash = '0x' + hash;

      // change the password in the hash in the form data
      data.set('password', hash);

      // Upload form to server with fetch API
      FetchBackendEndpoint('signup', data, signInCallback, homeData.setServerFailure);
    }
    else
      alert("The terms and captcha must be active to sign up.");
  };

  const handleResetUserPassword = (event) => {
    event.preventDefault(); 
    const formdata = new FormData(event.target);
    const userid = formdata.get('name');

    let data = new FormData();
    let currentDate = new Date();

    // change the password in the hash in the form data
    data.append('userid', userid);
    data.append('timestamp', currentDate.getTime());

    FetchBackendEndpoint('request_reset', data, resetCallback, homeData.setServerFailure);
  }

/*
            <div>
              <p style={{fontSize:"24px"}}>0 archived</p>
              <hr/>
            </div>
            <div>
              <p style={{fontSize:"24px"}}>0 trashed</p>
              <hr/>
            </div>
*/

  // ---------------------------------------------------------------
  // Return the User Menu component HTML (left top header)
  // ---------------------------------------------------------------
  return (
      <div className="right_top_header">
        <div align="left">
          <div ref={userMenuRef} align="center" className="loginDialog"
               style={{backgroundColor: userLevel.level === LevelEntrepreneur ? "#C0C0C0" :
                                         userLevel.level === LevelBusiness ? "#C9AE5D" : 
                                         userLevel.level === LevelEnterprise ? "#83B496" :
                                        "#DFDFDF"}}>
            <div role="button" tabIndex="0"
              onClick={() => { setOpenAccount(true);
                               setSignUp(false);}}
              onFocus={() => { setOpenAccount(true) }}
            >
              <img style={{ borderRadius: "40px"}} src={userLogo && userLogo.length > 0 ? userLogo : "./account.png"} alt="logo" width="70px" />
            </div>
            { userID ? userID: "Guest"}
            <div style={{display: openAccount === true && signUp === false && resetPassword === false && !userID ? "inherit" : "none"}}>
              <br />
              { !signUp ? LoginForm(handleLoginSubmit) : ""  }
              <br />
              <div align="left" style={{display: loginFails >= 3 ? "inherit" : "none",
                                        color: "white"}}>RECOVER? &nbsp;
              <button onClick={() => { setResetPassword(true); }}>
                Reset Password
              </button>
              </div>
              <div align="left"> &nbsp; SIGN UP &nbsp;
              <button title="Register" onClick={() => { setSignUp(true); }}>
                &nbsp;&nbsp;
                <img alt="register" src="./Registration.png" width="20px"></img>
                &nbsp;&nbsp;
              </button>
              </div>
            </div>
            <div style={{display: resetPassword === true ? "inherit" : "none"}}>
              <br />
              <form onSubmit={handleResetUserPassword}>
                <input style={{width: "80%"}}
                  type="text"
                  name="name"
                  placeholder="User name or email"
                  minLength="5"
                  maxLength="128"
                />
                <br />
                <button type="submit">
                  Send reset email
                </button>
              </form>
            </div>

            <div /*ref={inputAutofilledRef}*/ style={{display: signUp === true ? "inherit" : "none"}}>
                  <div align="left"> &nbsp; Already registered? &nbsp;
                    <button title="Login" onClick={() => { setSignUp(false); setOpenAccount(true); }}>
                      &nbsp;&nbsp;
                      Login
                      &nbsp;&nbsp;
                    </button>
                  </div>
              { signUp ? SignUpForm(handleSignUpSubmit) : "" }
            </div>
            <div align="center" style={{display: openAccount === true && userID ? "inherit" : "none"}}>
              { userLevel?.level >= LevelTrialist ?
                 <div align="center">
                  <sup>
                    {userLevel?.level === LevelEnterprise ? "Enterprise" :
                       userLevel?.level === LevelBusiness ? "Business" :
                       userLevel?.level === LevelEntrepreneur ? "Enterpreneur" : "Trialist"}
                  </sup>
                  <br/>
                  <sup>Expires {userLevel?.expires.length > 0 ? userLevel.expires : "never"}</sup>
                  <br/>
                  <sup>Usage {Math.round(userUsage / ( 1024 * 1024)) }MB/{userLevel?.level === LevelEnterprise ? "5000" :
                       userLevel?.level === LevelBusiness ? "500" : userLevel?.level === LevelEntrepreneur ? "50" : "5"}MB</sup>
                  <br />
                  <br />
                  <Link title="User Profile" style={{visibility: userLevel?.level >= 0 ? "inherit" : "hidden"}}
                        className="formButton" to="/FormAlly/ImmutableSoft/FormAlly Profile"
                        state={{"inheritState": {"username": userID},
                                "initState": { "stripe_pk": userStripePk, "logo": userLogo,
                                               "session_timeout": userTimeout },
                                               "redirect": process.env.REACT_APP_URL }}>
                    &nbsp;&nbsp;
                    <img alt="profile" src="./file-user.svg" width="20px"></img>
                    &nbsp;&nbsp;
                  </Link>
                </div>
                :
                ""
                }
                  <br/>
                <button title="Sign Out" className="formButton" onClick={() => {
                  // Clear state
                  setUserID(null);
//                  setUserPassword(null);
//                  setUserPrivateForms(null);
//                  setUserPublicForms(null);
                  setUserLogo(null);
                  setOpenAccount(false);
                  setUserLevel({level:0,expires:0});
//                  setUserLastLogin(null);

                  // Clear browser session
                  window.sessionStorage.removeItem("userID");
                  window.sessionStorage.removeItem("userLogo");
                  window.sessionStorage.removeItem("userPublicForms");
                  window.sessionStorage.removeItem("userPrivateForms");
                  window.sessionStorage.removeItem("userPassword");
                  window.sessionStorage.removeItem("userLastLogin");
                  window.sessionStorage.removeItem("userUsage");
                  window.sessionStorage.removeItem("userLevel");
                  homeData.incrUserReload();//setUserReload(homeData.userReload + 1);
                }}>
                  &nbsp;&nbsp;
                  <img alt="signout" src="./sign-out-alt.svg" width="20px"></img>
                  &nbsp;&nbsp;
                </button>
            </div>
          </div>
        </div>
      </div>
  );
}

export default UserMenu;
