import { useState, useEffect, useRef, useCallback } from "react"; 
import { Link } from "react-router-dom";
import '../../style/App.css';
import UsagePlans from "../UsagePlans"
import UserMenu from "../UserMenu"

function Pricing() {
  const [serverFailure, setServerFailure] = useState("");
  const [userReload, setUserReload] = useState(1);
  function incrUserReload() { setUserReload(userReload + 1) }
  const [userRelogin, setUserRelogin] = useState(1);
  const [showLogin, setShowLogin] = useState(1);
  function incrShowLogin() { setShowLogin(showLogin + 1) }

  const [userID, setUserID] = useState("");
  const [userPassword, setUserPassword] = useState();
  const [userPublicForms, setUserPublicForms] = useState([]);
  const [userPrivateForms, setUserPrivateForms] = useState([]);
  const [userLogo, setUserLogo] = useState();
  const [userLastLogin, setUserLastLogin] = useState();
  const [userLevel, setUserLevel] = useState({level:0,expires:0});
  const [userUsage, setUserUsage] = useState(0);

  // If on page (re)load the user/password are in session, redo login
  //   This is the easy way to keep form data arrays in sync ;-)
  useEffect(() => {
//    console.log("Reload user information from session: " + userReload);
    const id = window.sessionStorage.getItem("userID");
    setUserID(id);
    setUserLogo(window.sessionStorage.getItem("userLogo"));
    setUserPassword(window.sessionStorage.getItem("userPassword"));
    setUserPublicForms(JSON.parse(window.sessionStorage.getItem("userPublicForms")));
    setUserPrivateForms(JSON.parse(window.sessionStorage.getItem("userPrivateForms")));
    setUserLastLogin(window.sessionStorage.getItem("userLastLogin"));
    const level = window.sessionStorage.getItem("userLevel");
    if (level != null)
      setUserLevel(JSON.parse(level));
//    console.log("user " + id + " level: " + level);
    setUserUsage(window.sessionStorage.getItem("userUsage"));
  }, [userReload]);

  const defaultCallback = (data) => {
    if (data.success === true)
    {
      // Reload/re-login after backend operation
      setUserReload(userReload + 1);
      setUserRelogin(userRelogin + 1);
      setServerFailure("");
    }
    else if (data.message.endsWith('expired'))
    {
      setServerFailure(data.message);
      window.sessionStorage.removeItem("userID");
      window.sessionStorage.removeItem("userPassword");
      window.sessionStorage.removeItem("userPublicForms");
      window.sessionStorage.removeItem("userPrivateForms");
      incrShowLogin();
      console.log("Expired, opening sign in");
    }
    else
    {
      // Otherwise read and save in state login failure message
      setServerFailure(data.message);
    }
  };

  // ---------------------------------------------------------------
  // Return the App page HTML
  // ---------------------------------------------------------------
  return (
    <div className="App">
      <header className="App-header">
        <img src="./header_background.png" alt="logo" width="100%" />
        <div style={{position: "relative", top: "-50px", height: "0px"}}>
            <b style={{fontSize: "32px"}}>Empower Your Passion</b>
        </div>
      </header>
      <div className="left_top_header">
        <img width="80px" alt="branding" src="./Immutable_WhiteOnTransparent_Logo.png"/>
      </div>
      <div align="center" className="center_top_header">
        <Link className="textButton" title="Dashboard" to="/">
          Dashboard
        </Link>
        &nbsp;&nbsp;
        <Link className="textButton" title="Documentation" to="/Documentation">
          Documentation
        </Link>
        &nbsp;&nbsp;
        <b style={{fontSize:"16px"}}>Plans</b>
        <br />
        Form<i><b>Ally</b></i>
      </div>
      <font color="red">
        { serverFailure && serverFailure.length > 0 ? serverFailure : <br />}
      </font>
      <UsagePlans data={{ userID, userPassword, userLastLogin,
                          userLevel, defaultCallback }} />
      <UserMenu homeData={{ setServerFailure, incrUserReload, userRelogin, showLogin }} />
      <br />
      <br />
      <div style={{fontSize:"10px"}} align="left">
        © 2024 <a href="https://www.immutablesoft.org/">ImmutableSoft Inc.</a>
        <br/>
        <sup>UIcons by <a href="https://www.flaticon.com/uicons" target="_blank" rel="noreferrer noopener">Flaticon</a></sup>
        <br />
      </div>
    </div>
  );
}

export default Pricing;
