import { loadStripe } from '@stripe/stripe-js';

// Test credit card number 4242424242424242
//    CVC: 424
// ImmutableSoft stripe test "publishable key"
// pk_test_51Hvp3wDNc0EHOfjFJfJrNQ0SUTBwIFqCposfRgLufKzSo7HSTSdZuB9t5DSBe975KscCMxn4WatligN1s1FLgVFG00KC750C2m
// product id: price_1P8ScUDNc0EHOfjFYHP2BRtK
let stripePromise;
const getStripe = (publishableKey) => {
  if (!stripePromise) {
    stripePromise = loadStripe(publishableKey);
  }
  return stripePromise;
};

export default getStripe;
