import { useState, useEffect } from "react"; 
import { useLocation, useNavigate } from 'react-router-dom'
//import { ReactRenderForm, RenderFormToObject, RemoveFormSeparators } from "render-form"
import { FormRender, FormRenderToObject, FormRemoveSeparators } from "@immutablesoft/form-render"
import { FetchBackendEndpoint } from '../Backend'
import UserMenu from '../UserMenu'

function RenderForm()
{
  const [embed, setEmbed] = useState(false);
  const [serverFailure, setServerFailure] = useState("");
  const [userID, setUserID] = useState("");
  const [userPassword, setUserPassword] = useState();
  const [userLogo, setUserLogo] = useState();
  const [userReload, setUserReload] = useState(1);
  function incrUserReload() { setUserReload(userReload + 1) }
  const [userRelogin, setUserRelogin] = useState(1);
  const [showLogin, setShowLogin] = useState(1);
  function incrShowLogin() { setShowLogin(showLogin + 1) }

  const query = new URLSearchParams(window.location.search);
  var redirect = query.get('redirect');
  if (redirect === undefined)
    redirect = null;
  var inheritState = null;
  var initState = null;
  var replaceIndex = -1;

//  const userID = window.sessionStorage.getItem("userID");
//  const userLogo = window.sessionStorage.getItem("userLogo");
//  const userPassword = window.sessionStorage.getItem("userPassword");
  const location = useLocation()

  // Read any inherit or init state variables
  //  These are used to auto populate the values in the rendered form
  //    inheritState takes presendence and also sets the field read only
  var form = null;
  if (location)
  {
    if (location.state)
    {
      if (location.state.inheritState !== undefined)
        inheritState = location.state.inheritState;
      if (location.state.initState !== undefined)
        initState = location.state.initState;
      
      if (location.state.redirect !== undefined)
        redirect = location.state.redirect;
      if (location.state.replaceIndex !== undefined)
        replaceIndex = location.state.replaceIndex;
//      console.log(JSON.stringify(location.state));

      form = location.state.form;
    }
  }

  const navigate = useNavigate();

  // If on page (re)load the user/password are in session, redo login
  //   This is the easy way to keep form data arrays in sync ;-)
  useEffect(() => {
    const uid = window.sessionStorage.getItem("userID");
    const pw = window.sessionStorage.getItem("userPassword");
    const logo = window.sessionStorage.getItem("userLogo");
    setUserID(uid);
    setUserPassword(pw);
    setUserLogo(logo);
    console.log("Reload " + uid + " password " + pw + "from session: " + userReload);
  }, [userReload]);

  const submitCallback = (data) => {
    if (data.success === true)
    {
      console.log("Form submit success for " + data.name);
      setServerFailure("Success, form submitted");
      setTimeout(function() { setServerFailure("") }, 3000);
    }
    else if (data.message.endsWith('expired'))
    {
      setServerFailure(data.message);
      incrShowLogin();
      console.log("Expired, opening sign in");
    }
    else
    {
      // Otherwise report the edit failure message
      setServerFailure("Failed. " + data.message);
    }
  };


  // ---------------------------------------------------------------
  // Submit button handling form submission
  // ---------------------------------------------------------------
  const handleFormSubmit = (event) => {
    event.preventDefault(); 
    var data = new FormData(event.target);

    // Get the form name and delete
    const name = data.get("formally_name");
    data.delete("formally_name");
    const version = data.get("formally_version");
    data.delete("formally_version");
    const username = data.get("formally_username");
    data.delete("formally_username");
    const formallyName = {name, version, username};
    console.log("FormSubmit:" + JSON.stringify(formallyName));

    var formData = FormRenderToObject(data, "formally_separator_3210",
                                      inheritState, initState);
    console.log(JSON.stringify(formData, null, 2));

    data = FormRemoveSeparators(data, "formally_separator_3210");

/*
    // If inherit/init then this is an edit
    // Replace the original file name back if not a new file uploaded
    if ((inheritState != null) || (initState != null))
    {
      data.forEach(function(value, key)
      {
        // Check all file objects... if they are empty assign initial
        //   file name values (inherit or init).
        if (value instanceof File)
        {
          if (formData[key] != value.name)
            console.log("Something wrong, " + formData[key] + " != " + value.name);

          if (value.name == "")
          {
            if ((inheritState != null) && inheritState[key])
              formData[key] = inheritState[key];
            else if ((initState != null) && initState[key])
              formData[key] = initState[key];
          }
        }
      });
    }
*/

    // Construct the derived form submission data
    data.append('username', userID);
    data.append('password', userPassword);
    data.append('formally', JSON.stringify(formallyName));
    data.append('form', JSON.stringify(formData));

/*
    // Add inherited state if any
    //   Inherited state replaces existing entry with same value(s)
    if ((inheritState !== null) || (initState !== null))
    {

      console.log("Setting replace init: " + JSON.stringify(initState));
      console.log("  inherit: " + JSON.stringify(inheritState));

      // Append the state objects and add the replace field
      if (inheritState === null)
        data.append('replace', JSON.stringify(initState));
      else if (initState === null)
        data.append('replace', JSON.stringify(inheritState));
      else
        data.append('replace',
            JSON.stringify(Object.assign(inheritState, initState)));
    }
*/
    if (replaceIndex >= 0)
      data.append('replaceIndex', replaceIndex);

    console.log(JSON.stringify(formData));

    FetchBackendEndpoint('formally/submit', data, submitCallback, setServerFailure);
  };

  const formStyle = [];
  formStyle.input = { width: "80%" };

  return (
    <div>
      <UserMenu homeData={{ setServerFailure, incrUserReload, userRelogin, showLogin }} />
      <div align="left">
        <p>
          &nbsp;
          <button title="Go Back" onClick={() => { navigate(-1) }}>
                            &nbsp;&nbsp;
                            <img src="./arrow-small-left.svg" width="20px"></img>
                            &nbsp;&nbsp;
          </button>
          &nbsp;&nbsp;or use the Back button when finished
        </p>
      </div>
      <FormRender nameObj={form.formally} renderFields={form.form}
                  handleSubmitFunction={handleFormSubmit} logo={userLogo}
                  submitString={initState || inheritState ? "Change" : "Submit"} style={formStyle}
                  state={{ inheritState, initState }}/>
      <div align="center">
        { typeof serverFailure === "string" ?
           !serverFailure.includes("Success,") ?
            <font color="red">
              { serverFailure ? serverFailure : <br />}
            </font> :
            serverFailure :
             <br />
        }
      </div>
      <br />
      <hr />
      <div align="center">
        Display this form on your website as an iframe
        <br />
        <button onClick={(e) => setEmbed(!embed)}>{ embed ? "Hide " : "Show "}Code</button> 
        <br />
        {
          embed ? '<iframe ' +
            'width="80%" height="' + (form.form.length * 100) +
            'px" frameBorder="0" src="' + process.env.REACT_APP_URL +
            'formally/' + form.formally.username + '/' + form.formally.name +
            '"></iframe>'
            :
            ""
        }
        <br />
      </div>
      <br />
      <div align="center">
      </div>
    </div>
    );
}

export default RenderForm;
