import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FetchBackendEndpoint } from './Backend.js'
import "../style/App.css";

import {
//  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
//  setColumnVisibility,
//  SortingFn,
//  SortingState,
  useReactTable,
//  isRowSelected,
  createColumnHelper,
} from '@tanstack/react-table'

const Table = ({ tableData }) => {
  const [serverMessage, setServerMessage] = useState("");
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [sorting, setSorting] = React.useState([]);//<SortingState>([])

  const navigate = useNavigate();

  // UI table functions
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

//  console.log("TableAll: " + JSON.stringify(tableData));
//  console.log("TableForm: " + JSON.stringify(tableData.form));
//  console.log("TableData: " + JSON.stringify(tableData.formData));

  const columns = useMemo(
   () => {
    const tempColumns = [];
    const columnHelper = createColumnHelper();

    const submissionCallback = (resultData) => {
      if (resultData.success === true)
      {
        console.log("Submission operation success for " + resultData.name);
        setServerMessage("The submission operation was successful." + resultData.index);
        tableData.formData.splice(resultData.index, 1);
        navigate('.', { "state": { "form": tableData.form }});
      }
      else
      {
        // Otherwise report the edit server message
        setServerMessage(resultData.message);
      }
    };

    // ---------------------------------------------------------------
    // Submit handling for submission queue
    // ---------------------------------------------------------------
    const handleQueueSubmit = async (row) => {

      const newData = new FormData();

      // Set form data and delete index as parameters to submit endpoint
      newData.append('formally', JSON.stringify(tableData.form.formally));
//      newData.append('form', JSON.stringify(row.original));
      newData.append('username', tableData.userID);
      newData.append('password', tableData.userPassword);
      newData.append('source', (tableData.showPublicData ? "./public/" : "./private/") +
        tableData.form.formally.username + "/data/");
      newData.append('destination', (tableData.showPublicData ? "./private/" : "./public/") +
        tableData.form.formally.username + "/data/");
      newData.append('queue', (tableData.formData.length - 1) - row.index);

      FetchBackendEndpoint('formally/queue', newData, submissionCallback, setServerMessage);
    };

    // ---------------------------------------------------------------
    // Submit handling for submission delete
    // ---------------------------------------------------------------
    const handleDeleteSubmit = async (row) => {

      const newData = new FormData();

      // Set form data and delete index as parameters to submit endpoint
      newData.append('formally', JSON.stringify(tableData.form.formally));
      newData.append('form', JSON.stringify(row.original));
      newData.append('username', tableData.userID);
      newData.append('password', tableData.userPassword);

      newData.append('deleteIndex', (tableData.formData.length - 1) - row.index);

      FetchBackendEndpoint('formally/submit', newData, submissionCallback, setServerMessage);
    };

    const handleDownloadSubmit = async (fileName) => {
      const { userID, userPassword } = tableData;

      var data = new FormData();

      // pass the current user, password and file to download
      data.set('username', userID);
      data.set('password', userPassword);
      data.set('filename', fileName);

      if ((fileName === null) || (fileName === undefined) ||
          (fileName.length <= 0))
      {
        setServerMessage("Filename required.");
        return;
      }
  //    alert("before download post");
      // Download the file using the fetch API to the server
      fetch(process.env.REACT_APP_BACKEND_URL + 'download', {
        method: 'POST',
        body: data
      })
        .then((res) => {
                         if (res.status == 200)
                           return res.blob();
                         else
                           return Promise.reject(res);
                       })
        .then((data) => {
           // Hack to create a link out of the result and click it
           // to trigger the browser to download.
           var a = document.createElement("a");
           a.href = window.URL.createObjectURL(data);
           a.download = fileName.split('/')[fileName.split('/').length - 1];
           console.log("Downloading... " + a.download);
           a.click();
        })
        .catch((err) => {
            if (err.statusText)
              setServerMessage(err.statusText);
            else if (err.statusString)
              setServerMessage(err.statusString);
            else
              setServerMessage(": Server unreachable?");
        });
    };


/*
                <Link className="formButton" to={"/FormAlly/" + data.form.formally.username + "/" + data.form.formally.name}
                        state={{"initState": row.cell.row.original, "redirect": process.env.REACT_APP_URL }}>
                  &nbsp;&nbsp;Edit&nbsp;&nbsp;
                </Link>
*/

     // Put edit button on left
     tempColumns.push({
            header: "Modify",
            cell: (row) => {
              return (
               <div width="100%" style={{display:"inline-block"}}>
                 {
                   !tableData.showPublicData ?
                   <>
                    <button title="Delete"
                      onClick={(e) => { handleDeleteSubmit(row.cell.row) }}
                    >
                      <img alt="Delete" src="./trash-slash.svg" width="20px"></img>
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link title="Edit" className="formButton" to={"/RenderForm"}
                       state={{"form": tableData.form,
                               "replaceIndex": (tableData.formData.length - 1) - row.cell.row.index,
                               "initState": row.cell.row.original,
                               "redirect": process.env.REACT_APP_URL + "ViewFormData"}}>
                      <img alt="Edit" src="./file-edit.svg" width="20px"></img>
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </>
                  :
                  ""
                }
                {
                  (tableData.userLevel?.level >= 3) ?
                    <button title={tableData.showPublicData ? "Push to Staging Queue" : "Push to Live Queue"}
                      onClick={(e) => { handleQueueSubmit(row.cell.row) }}
                    >
                      <img alt="Queue" src={tableData.showPublicData ? "./cloud-download.svg" : "./cloud-upload.svg"} width="20px"></img>
                    </button>
                  :
                  ""
                }
              </div>
              );
            },
            accessorKey: "modify",
          });
      tableData.form.form.map((column, index) =>
        {
          if ((column.type === "files") || (column.type === "video") ||
              (((column.type === 'file') || (column.type === 'image')) &&
                (Number(column.min) !== 1)))
            tempColumns.push(
              columnHelper.accessor(column.name, {
                cell: url => {
                    const urlValue = url.getValue();
                    var urlArray = [];
                    if (urlValue && urlValue.length > 0)
                      urlArray = urlValue.split(',');
                    return urlArray.map((eachUrl) =>
                                ((column.type === 'image') && ((Number(column.min) === 2) || tableData.showPublicData)) ?
                                  <div width="100%" style={{display:"inline-block"}}>
                                    <a href={(Number(column.min) === 2) ? eachUrl :
                                               process.env.REACT_APP_BACKEND_URL +
                                               tableData.form.formally.username + "/data/" +
                                               tableData.form.formally.name + "/" + eachUrl}
                                     target="_blank" rel="referrrer noopener">
                                      <img alt={eachUrl}
                                       src={(Number(column.min) === 2) ? eachUrl :
                                              process.env.REACT_APP_BACKEND_URL +
                                              tableData.form.formally.username + "/data/" +
                                              tableData.form.formally.name + "/" + eachUrl}
                                       width="40px">
                                      </img>
                                    </a>
                                  </div>
                                : (column.type.startsWith('file') && tableData.showPublicData) ?
                                  <div width="100%" style={{display:"inline-block"}}>
                                    <a href={process.env.REACT_APP_BACKEND_URL +
                                     tableData.form.formally.username + "/data/" +
                                     tableData.form.formally.name + "/" + eachUrl}
                                     target="_blank" rel="referrrer noopener">
                                      {eachUrl}
                                    </a>
                                    &nbsp;&nbsp;
                                  </div>
                                :
                                ((column.type === 'video') && (tableData.showPublicData)) ?
                                  <div width="100%" style={{display:"inline-block"}}>
                                    <video controls width="100%">
                                      <source src={tableData.userID + "/data/" +
                                                   tableData.form.formally.name + "/" + eachUrl}
                                              type={"video/" + eachUrl.split('.').pop()} />
                                    </video>
                                  </div>
                                :
                                  <div width="100%" style={{display:"inline-block"}}>
                                    <button title={"Download " + eachUrl} key={eachUrl} onClick={() =>
                                     handleDownloadSubmit(tableData.userID + "/data/" +
                                          tableData.form.formally.name + "/" + eachUrl)}>
                                      <img alt="Download" src="./download.svg" width="15px"></img>
                                    </button>
                                    {eachUrl}
                                  </div>
                              );
                }
              })
            );
          else if ((column.type === 'signature') ||
                   (((column.type === 'image') || (column.type === 'file'))
                      && (Number(column.min) === 1)))
            tempColumns.push(
              columnHelper.accessor(column.name, {
                cell: image => {
                    if (image.getValue() && image.getValue().startsWith('data:image/'))
                      return (<img alt={column.name} src={image.getValue()} width="100px"></img>);
                    else
                      return image.getValue() ? image.getValue().slice(0, 30) : "";
                }
              })
            );
          else if (column.type === 'timestamp')
            tempColumns.push(
              columnHelper.accessor(column.name, {
                cell: url => {
                                const timestamp = new Date(Number(url.getValue()));
                                return timestamp.toLocaleString('en-US');
                              }
              })
            );
          else if (column.type === 'captcha')
            tempColumns.push(
              columnHelper.accessor(column.name, {
                cell: siteKey => {
                                return siteKey.getValue() ? siteKey.getValue().slice(0, 27) + "..." : "";
                              }
              })
            );
          else if (column.type === 'source')
            tempColumns.push(
              columnHelper.accessor(column.name, {
                cell: origin => {
                                const data = origin.getValue();
                                return data.form.formally.name + '\n' +
                                       JSON.stringify(data.data);
                              }
              })
            );
          else
            tempColumns.push({ header: () => capitalizeFirstLetter(column.name), accessorKey: column.name });
          return tempColumns;
        });

/*
 *                        <button onClick={() => this.handleDownloadSubmit(this.state.userID + "/" + attribute.value)}>
                            &nbsp;&nbsp;Download {attribute.value}&nbsp;&nbsp;
                          </button>
*/

/*
     // And trash on right side
     tempColumns.push({
            header: "Delete",
            cell: (row) => {
              return (
                <button 
                  onClick={(e) => { console.log("Delete " + JSON.stringify(row));}}
                >
                  Delete
                </button>
              );
            },
            accessorKey: "delete",
          });*/
     return tempColumns;
   }, [tableData, navigate]);

//  console.log("Columns: " + JSON.stringify(theColumns));


//  console.log("theData: " + JSON.stringify(data.formData));

  const table = useReactTable({
    data : tableData.formData,
    columns,
//    debugTable: true,
//    debugHeaders: true,
//    debugColumns: true,
    onColumnVisibilityChange: setColumnVisibility,
//    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,

    state: {
      columnVisibility,
      sorting,
    },
  });

/*
  const handleDelete = (event) => {
    event.preventDefault(); 
  }
*/

  return (
     <div className="p-2">
      <div className="inline-block border border-black shadow rounded">
          <label>
            <input
              {...{
                type: 'checkbox',
                checked: table.getIsAllColumnsVisible(),
                onChange: table.getToggleAllColumnsVisibilityHandler(),
              }}
            />{' '}
            Toggle All
          </label>
        {table.getAllLeafColumns().map((column, index) => {
          return (
              <label key={index}>
                <input
                  {...{
                    type: 'checkbox',
                    checked: column.getIsVisible(),
                    onChange: column.getToggleVisibilityHandler(),
                  }}
                />{' '}
                {column.id}
              </label>
          )
        })}
      </div>
      <div className="h-4" />
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>

              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ' '
                        }
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                )
              })}


            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.map((row, index) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id} style={{whitespace: 'normal', maxWidth:'200px', wordWrap:'break-word'}}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <pre>{/*JSON.stringify(table.getState().columnOrder, null, 2)*/}</pre>
      <font color="red">
        { serverMessage }
      </font>
    </div>
  );
};

export default Table;
