function NotFound()
{
  return (
    <div align="center">
      <h2>404 Not Found</h2>
      A swing and a miss.
      <br/>
      <br/>
      <br/>
      <img alt="missed" src="./baseball-fail.gif" width="200px"></img>
    </div>
    );
}

export default NotFound;
