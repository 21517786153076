// ---------------------------------------------------------------
// Backend endpoint call
// ---------------------------------------------------------------
export function FetchBackendEndpoint(endpoint, data, callback, error)
{
  // Uploading the file using the fetch API to the server
  fetch(process.env.REACT_APP_BACKEND_URL + endpoint, {
    method: 'POST',
    body: data
  })
    .then((res) => res.json())
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      if (err.statusText)
        error(err.statusText);
          if (err.statusString)
            error(err.statusString);
          else
            error(err + ": Server unreachable?");
    });
};

