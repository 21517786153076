import { Routes, Route } from "react-router-dom";
import Home from './components/pages/Home.js'
import EditForm from './components/pages/EditForm.js'
import RenderForm from './components/pages/RenderForm.js'
import RenderPublicForm from './components/pages/RenderPublicForm.js'
import ViewFormData from './components/pages/ViewFormData.js'
import VerifyUser from './components/pages/VerifyUser.js'
import ResetUser from './components/pages/ResetUser.js'
import Pricing from './components/pages/Pricing.js'
import Documentation from './components/pages/Documentation.js'
import NotFound from './components/pages/NotFound.js'

function App() {
  // ---------------------------------------------------------------
  // Return the App routes
  // ---------------------------------------------------------------
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Pricing" element={<Pricing />} />
      <Route path="/Documentation" element={<Documentation />} />
      <Route path="/EditForm" element={<EditForm />} />
      <Route path="/RenderForm" element={<RenderForm />} />
      <Route path="/ViewFormData" element={<ViewFormData />} />
      <Route path="/FormAlly/*" element={<RenderPublicForm />} />
      <Route path="/VerifyUser/*" element={<VerifyUser />} />
      <Route path="/ResetUser/*" element={<ResetUser />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
