import { useState, useEffect } from "react"; 
import { useLocation, useNavigate } from 'react-router-dom'
//import { ReactEditForm, EditFormToObjectArray } from "edit-form"
//import { FormEdit, FormEditToObjectArray } from "@immutablesoft/form-edit"
//import { FormEdit, FormEditToObjectArray } from "../formAlly/formEdit"
import { FormEdit, FormEditToObjectArray } from "@immutablesoft/form-edit"
import { FetchBackendEndpoint } from '../Backend'
import UserMenu from '../UserMenu'

function EditForm()
{
  const [serverFailure, setServerFailure] = useState("");
  const [userID, setUserID] = useState("");
  const [userPassword, setUserPassword] = useState();
  const [userStripePk, setUserStripePk] = useState();
//  const [userLogo, setUserLogo] = useState();
  const [userReload, setUserReload] = useState(1);
  function incrUserReload() { setUserReload(userReload + 1) }
  const [userRelogin, setUserRelogin] = useState(1);
  const [showLogin, setShowLogin] = useState(1);
  function incrShowLogin() { setShowLogin(showLogin + 1) }

//  var userID = window.sessionStorage.getItem("userID");
//  var userPassword = window.sessionStorage.getItem("userPassword");
//  var userLogo = window.sessionStorage.getItem("userLogo");
  const location = useLocation()
  const { form } = location.state
  const navigate = useNavigate();

  // If on page (re)load the user/password are in session, redo login
  //   This is the easy way to keep form data arrays in sync ;-)
  useEffect(() => {
    const uid = window.sessionStorage.getItem("userID");
    const pw = window.sessionStorage.getItem("userPassword");
    const stripe = window.sessionStorage.getItem("userStripePk");
    if (uid)
      setUserID(uid);
    if (pw)
      setUserPassword(pw);
    if (stripe)
      setUserStripePk(stripe);
    else
      setUserStripePk("");

//    setUserLogo(window.sessionStorage.getItem("userLogo"));
    console.log("Reload " + uid + " password " + pw + "from session: " + userReload);
    console.log("Stripe Pk: " + stripe);
  }, [userReload]);

  const editCallback = (data) => {
    if (data.success === true)
    {
//      console.log("Edit form success for " + data.name.name);
      setServerFailure("Form Saved");
      setTimeout(function() { setServerFailure("") }, 3000);
    }
    else if (data.message.endsWith('expired'))
    {
      setServerFailure(data.message);
      incrShowLogin();
      console.log("Expired, opening sign in");
    }
    else
    {
      // Otherwise report the edit failure message
      setServerFailure(data.message);
    }
  };

//  console.log(JSON.stringify(form));

  // ---------------------------------------------------------------
  // Submit button handling events for UI forms
  // ---------------------------------------------------------------
  const handleEditSubmit = (event) => { 
    event.preventDefault(); 
    const data = new FormData(event.target);

    // Get the form name and delete
    const name = data.get("formally_name");
    data.delete("formally_name");
    const version = data.get("formally_version");
    data.delete("formally_version");
    const username = data.get("formally_username");
    data.delete("formally_username");
    const formallyName = {name, version, username};
//    console.log("EditSubmit:" + JSON.stringify(formallyName));

    // Without form name, parse the form and populate object array
    var formData = FormEditToObjectArray(data, "formally_separator_3210");

    {
      const newData = new FormData();

      // change the password in the hash in the form data
      newData.append('username', userID);
      newData.append('password', userPassword);
      newData.append('formally', JSON.stringify(formallyName));
      newData.append('form', JSON.stringify(formData));

      FetchBackendEndpoint('formally/save', newData, editCallback, setServerFailure);
    }
  };

  return (
    <div>
      <UserMenu homeData={{ setServerFailure, incrUserReload, userRelogin, showLogin }} />
      <div align="left">
        <p>
          &nbsp;
          <button title="Go Back" onClick={() => { navigate(-1) }}>
                            &nbsp;&nbsp;
                            <img src="./arrow-small-left.svg" width="20px"></img>
                            &nbsp;&nbsp;
          </button>
          &nbsp;&nbsp;
          or download the form as JSON &nbsp;&nbsp;
          <a title="Export Form Definition" className="formButton" download={form.formally.name + '.json'} href={"data:text/json," + JSON.stringify(form, null, 2)}>
                            &nbsp;&nbsp;
                            <img src="./file-export.svg" width="20px"></img>
                            &nbsp;&nbsp;
          </a>
        </p>
      </div>
      <br />
      <div align="center">
        <FormEdit nameObj={form.formally} initialFields={form.form}
                  handleEditSubmit={handleEditSubmit} stripePk={userStripePk}
                  siteKey="6d3c4496-0d8a-48da-82c4-471620ccca8c"/>
      </div>
      <br />
      <div align="center">
        { typeof serverFailure === "string" ?
           !serverFailure.includes("Form Saved") ?
            <font color="red">
              { serverFailure ? serverFailure : <br />}
            </font> :
            serverFailure :
             <br />
        }
      </div>
    </div>
    );
}

export default EditForm;
