import { useState, useEffect } from "react"; 
import { useLocation } from 'react-router-dom'
import { FetchBackendEndpoint } from '../Backend.js'
import { useNavigate } from 'react-router-dom';
import Table from '../Table.js';
import UserMenu from '../UserMenu';

function ViewFormData()
{
  const [serverFailure, setServerFailure] = useState("");
  const [formData, setFormData] = useState();
//  const [reload, setReload] = useState(1);

  const [userID, setUserID] = useState("");
  const [userPassword, setUserPassword] = useState();
  const [userLevel, setUserLevel] = useState({level:0,expires:0});
  const [userReload, setUserReload] = useState(1);
  function incrUserReload() { setUserReload(userReload + 1) }
  const [userRelogin/*, setUserRelogin*/] = useState(1);
  const [showLogin/*, setShowLogin*/] = useState(1);
  const [showPublicData, setShowPublicData] = useState(false);
//  function incrShowLogin() { setShowLogin(showLogin + 1) }

//  const userID = window.sessionStorage.getItem("userID");
//  const userLogo = window.sessionStorage.getItem("userLogo");
//  const userPassword = window.sessionStorage.getItem("userPassword");
  const location = useLocation();
  const { form } = location.state;

  const navigate = useNavigate();

  useEffect(() => {
    const uid = window.sessionStorage.getItem("userID");
    const pw = window.sessionStorage.getItem("userPassword");
    var userLevel = window.sessionStorage.getItem("userLevel");
    userLevel = JSON.parse(userLevel);
    setUserID(uid);
    setUserPassword(pw);
    setUserLevel(userLevel);
//    console.log("ViewData Reload " + uid + " password " + pw + " level " + userLevel?.level + " from session: " + userReload);

    if (uid && pw && userReload)
    {
      if ((userLevel?.level >= 3) && showPublicData)
      {
        fetch(process.env.REACT_APP_BACKEND_URL + form.formally.username + "/data/" + form.formally.name + ".json")
          .then(response => response.json())
            .then(obj => { setFormData(obj.reverse()); })
          .catch(error => {
             setServerFailure("Form " + form.formally.name + " public data not found.");
             setFormData(null);
          });
      }
      else
      {
        let data = new FormData();

        // change the password in the hash in the form data
        data.append('username', userID);
        data.append('password', userPassword);
        data.append('formally', JSON.stringify(form.formally));

        FetchBackendEndpoint('formally/data', data, dataCallback, setServerFailure);
      }
    }
//    else
//      console.log("useEffect to load data before username set");
  }, [showPublicData, userReload, userID, userPassword, form.formally]);

  const dataCallback = (data) => {
    if (data.success === true)
    {
      // Read and save the userID from server response
      //   ALWAYS BUILD IN REVERSE ORDER, showing most recent at top
      if (data.formData)
        setFormData(data.formData.reverse());
      else
        setFormData(data.formData);
      setServerFailure("");
    }
    else
    {
      // Otherwise read and save in state login failure message
      setServerFailure(data.message);
    }
  };

  return (
    <div>
      <UserMenu homeData={{ setServerFailure, incrUserReload, userRelogin, showLogin }} />
      <div align="left">
        <p>
          &nbsp;
          <button title="Go Back" onClick={() => { navigate('/') }}>
                            &nbsp;&nbsp;
                            <img alt="Back" src="./arrow-small-left.svg" width="20px"></img>
                            &nbsp;&nbsp;
          </button>
          &nbsp;or download all the data with &nbsp;&nbsp;
          <a title="Export Submission Data" className="formButton" download={form.formally.name + '_data.json'}
            href={"data:text/json," + JSON.stringify(formData, null, 2)}>
                            &nbsp;&nbsp;
                            <img alt="export" src="./file-export.svg" width="20px"></img>
                            &nbsp;&nbsp;
          </a>
        </p>
        { (userLevel?.level >= 3) ?
            <>
              &nbsp;&nbsp;
              View {showPublicData ? "staging" : <>&nbsp;&nbsp;&nbsp;live&nbsp;&nbsp;&nbsp;</>} data
              &nbsp;<button title={showPublicData ? "Show Staging" : "Show Live"} onClick={() => { setShowPublicData(!showPublicData) }}>
                            &nbsp;&nbsp;
                            <img alt="swap queue" src="./replace.svg" width="20px"></img>
                            &nbsp;&nbsp;
               </button>&nbsp;
            </>
            :
            ""
         }
      </div>
      <br/>
      <div align="center">
        <h2>{form.formally.name}: {showPublicData ? "Live " : "Staging "}</h2>
        { formData ? <Table tableData={ { form, formData, userID, userPassword, showPublicData, userLevel }} /> : ""}
      </div>
      <br />
      <div align="center">
        <font color="red">
          { serverFailure }
        </font>
      </div>
    </div>
    );
}

export default ViewFormData;
