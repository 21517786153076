import { useState, useEffect, useRef } from "react"; 
import { useNavigate } from 'react-router-dom'
import { FetchBackendEndpoint } from '../Backend.js'
import { sha256 } from "js-sha256";

function ResetUser()
{
  const [serverMessage, setServerMessage] = useState("");
  const [userName, setUserName] = useState("");
  const [userHash, setUserHash] = useState("");
  const [userTimestamp, setTimestamp] = useState("");
  const navigate = useNavigate();
  const initialized = useRef(false);

  // Load the user from the URL or redirect to notfound url
  //  <URL>/<username>/<hash>/<timestamp>
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      let urlArray = window.location.href.split("/");
      if (urlArray.length >= 4)
      {
        let timestamp =  urlArray[urlArray.length - 1];

        // Check time, if within an hour it is in session
        var currentTime = new Date();
        if ((timestamp > currentTime.getTime() + (1000 * 60 * 60)) ||
            (timestamp < currentTime.getTime() - (1000 * 60 * 60)))
        {
          console.log('Timed out link.');
          setServerMessage("Reset link timed out. You will be redirected home.");
          setTimeout(function () { navigate("/") }, 3000);
        }
        else
        {
          setTimestamp(timestamp);
          setUserName(urlArray[urlArray.length - 3]);
          setUserHash(urlArray[urlArray.length - 2]);
//          console.log("ResetUser " + urlArray[urlArray.length - 3] + ":" +
//                      urlArray[urlArray.length - 1]);
        }
      }
      else
        navigate("/NotFound");
    }
  }, [navigate]);

  const resetCallback = (data) => {
//    console.log("resetCallback " + JSON.stringify(data));
    if (data.success === true)
    {
      setServerMessage("User " + data.userid + " password reset. You will be redirected now.");
      setTimeout(function () { navigate("/") }, 3000);
    }
    else
    {
      // Otherwise read and save in state login message
      setServerMessage(data.message);
    }
  };

  const handleResetUserPassword = (event) => {
    event.preventDefault(); 
    const formdata = new FormData(event.target);
    const password = formdata.get('password');
    const password2 = formdata.get('password2');

    if (password === password2)
    {
      var hash = sha256(password);

      // Strip off leading zeros
      while (hash[0] === '0')
        hash = hash.substring(1);

      // Preceed with hex and compare to the release hash
      hash = '0x' + hash;

      let data = new FormData();

      // change the password in the hash in the form data
      data.append('username', userName);
      data.append('hash', userHash);
      data.append('timestamp', userTimestamp);
      data.append('password', hash);

      FetchBackendEndpoint('reset', data, resetCallback, setServerMessage);
    }
    else
      setServerMessage("Passwords do not match.");
  }
  
  return (
    <div align="center">
      <div style={{visibility: userName.length > 0 ? "inherit" : "hidden"}}>
        Change password for {userName}
        <form onSubmit={handleResetUserPassword}>
          <input 
            type="password"
            name="password"
            placeholder="New Password"
            minLength="5"
            maxLength="32"
          />
          <br/>
          <input 
            type="password"
            name="password2"
            placeholder="Re-enter new Password"
            minLength="5"
            maxLength="32"
          />
          <br/>
          <button type="submit">Change Password</button>
        </form>
      </div>
      <br/>
      <br />
      { typeof serverMessage === "string" ?
          !serverMessage.includes('password reset.') ?
          <font color="red">
            { serverMessage }
          </font>
          : serverMessage
            :
              <br />
      }
    </div>
    );
}

export default ResetUser;
